import { Image } from '@/types';
import { Box } from '@mui/material';
import { CloseIcon } from './CloseIcon';
import { filterDeletedItems } from '@/utils/filterDeletedItems';

export function ImageGallery({
  images = [],
  onDelete,
}: {
  images: Array<Image>;
  onDelete: (image: Image) => void;
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(3, 1fr)',
          md: 'repeat(4, 1fr)',
        },
        justifyContent: 'center',
        width: 1,
        gap: 2,
        px: 2,
      }}
    >
      {filterDeletedItems(images).map((image, index) => (
        <div
          key={`image-gallery-img-${index}`}
          style={{
            position: 'relative',
          }}
        >
          <CloseIcon
            onClick={() => onDelete(image)}
            style={{
              position: 'absolute',
              top: -10,
              right: -10,
              cursor: 'pointer',
            }}
          />
          <img
            src={(image.base64String || image.url) as string}
            alt={`Room Photo ${index}`}
            width="140px"
            height="140px"
            style={{
              objectFit: 'cover',
              width: '100%',
              maxHeight: '100%',
            }}
          />
        </div>
      ))}
    </Box>
  );
}
