import { Item } from '@/types';

// Combines new item with original item by totaling weights and counts and
// setting dimensions to be the larger value of the two.
export const combineItems = (originalItem: Item, newItem: Item) => {
  return {
    ...originalItem,
    going: newItem.going + originalItem.going,
    notGoing: newItem.notGoing + originalItem.notGoing,
    packing: (newItem.packing ?? 0) + (originalItem.packing ?? 0),
    unpacking: (newItem.unpacking ?? 0) + (originalItem.unpacking ?? 0),
    weight: (newItem.weight ?? 0) + (originalItem.weight ?? 0),
    cube: newItem.cube + originalItem.cube,
    length:
      (newItem.length ?? 0) > (originalItem.length ?? 0)
        ? newItem.length
        : originalItem.length,
    width:
      (newItem.width ?? 0) > (originalItem.width ?? 0)
        ? newItem.width
        : originalItem.width,
    height:
      (newItem.height ?? 0) > (originalItem.height ?? 0)
        ? newItem.height
        : originalItem.height,
    roomId: newItem.roomId,
    segmentId: newItem.segmentId,
  };
};
