import { CardContent } from '@mui/material';
import { Stack } from '@mui/system';
import ChairIcon from '@mui/icons-material/Chair';
import { AddRoomButton } from '../AddRoomButton';
import { RoomListItem } from '@/types';

interface RoomListEmptyProps {
  onAddRoom: (room: RoomListItem) => void;
}

export function RoomListEmpty({ onAddRoom }: RoomListEmptyProps) {
  return (
    <CardContent
      sx={{
        backgroundColor: '#FAFAFB',
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" spacing={2} height={1}>
        <ChairIcon
          sx={{
            fontSize: '8em',
            color: 'rgba(24, 37, 85, 0.2)',
          }}
        />
        <AddRoomButton variant="outlined" onAddRoom={onAddRoom}>
          Add First Room
        </AddRoomButton>
      </Stack>
    </CardContent>
  );
}
