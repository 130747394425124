import { useAtom } from 'jotai';
import {
  segmentsAtom,
  roomsAtom,
  itemsAtom,
  surveyAtom,
  surveyIdAtom,
} from '../store';

export const useSurvey = () => {
  const [survey, setSurvey] = useAtom(surveyAtom);
  const [segments, setSegments] = useAtom(segmentsAtom);
  const [rooms, setRooms] = useAtom(roomsAtom);
  const [items, setItems] = useAtom(itemsAtom);
  const [id, setSurveyId] = useAtom(surveyIdAtom);

  return {
    survey,
    setSurvey,
    segments,
    setSegments,
    rooms,
    setRooms,
    items,
    setItems,
    id,
    setSurveyId,
  };
};
