import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

const Modes = {
  numeric: {
    type: 'text',
    inputMode: 'numeric',
    pattern: '[0-9]*',
  },
  decimal: {
    type: 'number',
    inputMode: 'decimal',
    pattern: '[0-9.]*',
    step: '0.01',
  },
};

type NumberFieldProps = Omit<TextFieldProps, 'onChange'> & {
  value: string | number | null;
  inputMode?: 'numeric' | 'decimal';
  onChange: (value: number | null) => void;
};

const removeNonNumericCharacters = (value: string) => {
  // only allow numbers and decimal point
  return value.replace(/[^0-9.]/g, '');
};

export function NumberField({
  value = '',
  inputMode = 'numeric',
  onChange,
  ...props
}: NumberFieldProps) {
  const handleChange = (value: string) => {
    const newValue = removeNonNumericCharacters(value);

    if (newValue === '') {
      onChange(null);
    } else {
      const numbericValue =
        inputMode === 'numeric' ? parseInt(newValue) : Number(newValue);
      onChange(numbericValue);
    }
  };

  return (
    <TextField
      {...props}
      value={value === null ? '' : value}
      onChange={(e) => handleChange(e.target.value)}
      inputProps={Modes[inputMode] as InputBaseComponentProps}
      sx={{
        ...props.sx,
        'input[type=number]': {
          MozAppearance: 'textfield',
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      }}
    />
  );
}
