import { Item } from '@/types';
import { isCartonItem } from './item/isCartonItem';

const isRoomItem = (item: Item) => !isCartonItem(item);

export const segmentRoomItems = (items: Array<Item>) => {
  return {
    room: items.filter(isRoomItem),
    carton: items.filter(isCartonItem),
  };
};
