import { ListItemButton, Typography, ListItem, List } from '@mui/material';
import { ItemDefinition } from '@/types';

interface ItemDefinitionListProps {
  title: string;
  itemDefinitions: Array<ItemDefinition>;
  onClick: (itemDefinition: ItemDefinition) => void;
}

export function ItemDefinitionList({
  title,
  itemDefinitions = [],
  onClick,
}: ItemDefinitionListProps) {
  return (
    <>
      <ListItem
        sx={{
          backgroundColor: ({ palette }) => palette.grey[100],
          height: 36,
        }}
      >
        <Typography>{title}</Typography>
      </ListItem>

      {/* 
          250px is what all the elements above these lists take up... roughly.
          This way the scroll ends at the bottom of the card.
      */}
      <List
        disablePadding
        sx={{
          height: 'calc(100% - 250px)',
          overflow: 'auto',
        }}
      >
        {itemDefinitions.map((itemDefinition: ItemDefinition) => {
          const key = `${title}-${itemDefinition.id}`;
          return (
            <ListItemButton
              key={key}
              onClick={() => onClick(itemDefinition)}
              divider
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 1,
              }}
            >
              <Typography variant="subtitle2">{itemDefinition.name}</Typography>
              <Typography variant="subtitle2">
                {itemDefinition.cube} cu ft
              </Typography>
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
}
