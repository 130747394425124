import { OBJECT_METHOD } from '@/constants';
import { Image } from '@/types';

const ensureArray = <T>(item: T | Array<T>): Array<T> => {
  return Array.isArray(item) ? item : [item];
};

const areSameImages = (image1: Image, image2: Image) => {
  return (
    (image1.id && image2.id && image1.id === image2.id) ||
    (image1.base64String === image2.base64String && image1.url === image2.url)
  );
};

const createImage = (base64String: string) => {
  return {
    base64String,
    _method: OBJECT_METHOD.CREATE,
  };
};

const createImages = (base64Strings: Array<string> | string) => {
  return ensureArray(base64Strings).map(createImage);
};

export const maybeAddImagesFromStrings = (
  existingImages: Array<Image>,
  newImageStrings: Array<string> | string
): Array<Image> => {
  const newImages = createImages(newImageStrings);
  const imagesToAdd = ensureArray(newImages).filter(
    (image) =>
      !existingImages.find(
        (existing) =>
          areSameImages(existing, image) &&
          existing._method !== OBJECT_METHOD.DESTROY
      )
  );
  return [...existingImages, ...imagesToAdd];
};

export const removeImages = (
  images: Array<Image>,
  imagesToRemove: Array<Image> | Image
) => {
  const imagesToRemoveList = ensureArray(imagesToRemove);
  return ensureArray(images).map((existingImage) => {
    const found = imagesToRemoveList.find((imageToRemove) =>
      areSameImages(existingImage, imageToRemove)
    );
    return Boolean(found)
      ? {
          ...existingImage,
          _method: OBJECT_METHOD.DESTROY,
        }
      : existingImage;
  });
};
