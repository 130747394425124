export const SERVICE_COMPLETION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  CREW_DISPATCHED: 'CREW_DISPATCHED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  PERFORMED: 'PERFORMED',
  CANCELED: 'CANCELED',
} as const;

export const SERVICE_COMPLETION_STATUSES = Object.keys(
  SERVICE_COMPLETION_STATUS
);
