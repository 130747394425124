import { ITEM_DEFINITIONS } from '@/constants';

interface IsCrateItemProps {
  name: string;
  [key: string]: unknown;
}

export const isCrateItem = (item: IsCrateItemProps) => {
  const definition = ITEM_DEFINITIONS.find(
    ({ name }: { name: string }) => name === item.name
  );
  return definition?.isCrate;
};
