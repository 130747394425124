import { useState } from 'react';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { Item } from '@/types';
import { itemsAtom } from '@/store';
import { ItemActionMenu } from '@/components';
import { removeItem, updateItem } from '@/utils';
import { ConfirmRemoveItemModal } from './ConfirmRemoveItemModal';
import { EditItemModal } from './EditItemModal';
import { RoomItem } from './RoomItem';
import { useSetAtom } from 'jotai';

const EmptyList = ({ text }: { text: string }) => (
  <ListItem divider sx={{ height: '100%' }}>
    <Stack height={1} width={1} justifyContent="center" alignItems="center">
      <Typography variant="h6" component="span">
        {text}
      </Typography>
    </Stack>
  </ListItem>
);

export function RoomItemsList({
  items,
  title,
  emptyText = 'No Items',
}: {
  items: Array<Item>;
  title: string;
  emptyText?: string;
}) {
  const setItems = useSetAtom(itemsAtom);
  const hasItems = Boolean(items.length);
  const [actionState, setActionState] = useState<{
    actionAnchorEl: HTMLElement | null;
    isActionOpen: boolean;
    isConfirmRemoveOpen: boolean;
    isEditOpen: boolean;
    item: Item | null;
  }>({
    actionAnchorEl: null,
    isActionOpen: false,
    isConfirmRemoveOpen: false,
    isEditOpen: false,
    item: null,
  });

  const handleItemChange = (item: Item) => {
    setItems((prevItems: Array<Item>) => updateItem(prevItems, item));
    closeModal('isEditOpen');
  };

  const handleRemoveItem = (item: Item) => {
    setItems((prevItems: Array<Item>) => removeItem(prevItems, item));
    closeModal('isConfirmRemoveOpen');
  };

  const handleItemRemoveFromModal = (item: Item) => {
    setItems((prevItems: Array<Item>) => removeItem(prevItems, item));
    closeModal('isEditOpen');
  };

  const closeActionMenu = () => {
    setActionState({
      ...actionState,
      actionAnchorEl: null,
      isActionOpen: false,
    });
  };

  const onItemClick = (item: Item, actionAnchorEl: HTMLElement) => {
    setActionState({
      ...actionState,
      actionAnchorEl,
      isActionOpen: true,
      item,
    });
  };

  const openModal = (modal: string) => {
    setActionState({
      ...actionState,
      isActionOpen: false,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setActionState({
      ...actionState,
      item: null,
      [modal]: false,
    });
  };

  return (
    <Stack height={1}>
      <List disablePadding>
        <ListItem
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '4fr 1fr 1fr 1fr 40px',
              sm: '5fr 1fr 1fr 1fr 40px',
            },
            width: '100%',
            alignItems: 'center',
            backgroundColor: '#1D4E8914',
            justifyContent: 'end',
          }}
        >
          <Typography sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {hasItems ? 'cu ft' : ''}
          </Typography>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {hasItems ? 'G' : ''}
          </Typography>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {hasItems ? 'NG' : ''}
          </Typography>
          <Typography />
        </ListItem>
      </List>

      <List
        disablePadding
        sx={{
          height: 0,
          minHeight: 'calc(100% - 40px);',
          overflowY: 'auto',
        }}
      >
        {hasItems ? (
          items.map((item: Item) => (
            <RoomItem key={item.id} item={item} onClick={onItemClick} />
          ))
        ) : (
          <EmptyList text={emptyText} />
        )}
      </List>

      <ItemActionMenu
        anchorEl={actionState.actionAnchorEl}
        open={actionState.isActionOpen}
        onClose={closeActionMenu}
        onRemoveItem={() => openModal('isConfirmRemoveOpen')}
        onEditItem={() => openModal('isEditOpen')}
      />

      {actionState.item ? (
        <EditItemModal
          open={actionState.isEditOpen}
          item={actionState.item}
          onClose={() => closeModal('isEditOpen')}
          onChange={handleItemChange}
          onRemove={handleItemRemoveFromModal}
        />
      ) : null}

      {actionState.isConfirmRemoveOpen ? (
        <ConfirmRemoveItemModal
          open={actionState.isConfirmRemoveOpen}
          item={actionState.item}
          onCancel={() => closeModal('isConfirmRemoveOpen')}
          onConfirm={handleRemoveItem}
        />
      ) : null}
    </Stack>
  );
}
