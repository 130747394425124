import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CRM_HOST } from '@/constants';
import { useSurvey } from '@/hooks';

export function SurveyCRMLink({ children }: { children: ReactNode }) {
  const { id } = useSurvey();
  const surveyUrl = `${CRM_HOST}/index.php?module=Cubesheets&view=Detail&record=${id}&sourceModule=Orders`;

  return (
    <Button
      href={surveyUrl}
      variant="contained"
      target="_blank"
      rel="noreferrer"
      endIcon={<OpenInNewIcon />}
    >
      {children}
    </Button>
  );
}
