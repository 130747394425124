import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface SurveyModal {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
  buttonText?: string;
  testID?: string;
}

export function SurveyModal({
  open,
  loading = true,
  onCancel,
  onConfirm,
  children,
  buttonText = 'Confirm',
  testID = 'SurveyModal',
}: SurveyModal) {
  return (
    <Dialog open={open} data-testid={`${testID}Dialog`}>
      {children}

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
          data-testid={`${testID}Button`}
          startIcon={
            loading ? <CircularProgress size="12px" color="inherit" /> : null
          }
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
