import { Atom, atom } from 'jotai';
import { ITEM_CATEGORY } from '@/constants';
import { itemsAtom } from './itemsAtom';
import { Item } from '@/types';

export const proGearItemsAtom = atom((get) => {
  const items = get(itemsAtom);
  return (items || []).filter(
    ({ category }) =>
      category === ITEM_CATEGORY.MEMBER_PRO_GEAR ||
      category === ITEM_CATEGORY.SPOUSE_PRO_GEAR
  );
});
