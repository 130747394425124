export enum SHIPMENT_TYPES {
  BOAT_HAUL_AWAY = 'BOAT_HAUL_AWAY',
  BOAT_TOW_AWAY = 'BOAT_TOW_AWAY',
  HHG = 'HHG',
  HHG_INTO_NTS_DOMESTIC = 'HHG_INTO_NTS_DOMESTIC',
  HHG_OUTOF_NTS_DOMESTIC = 'HHG_OUTOF_NTS_DOMESTIC',
  INTERNATIONAL_HHG = 'INTERNATIONAL_HHG',
  INTERNATIONAL_UB = 'INTERNATIONAL_UB',
  MOBILE_HOME = 'MOBILE_HOME',
  PPM = 'PPM',
}

export type ShipmentType = keyof typeof SHIPMENT_TYPES;
