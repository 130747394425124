import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const popin = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export default styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  place-items: start;
  align-items: center;
  animation: ${popin} 0.4s ease 0s;
  display: grid;
  grid-template-rows: minmax(100px, 700px) 1fr;
`;
