import { Survey } from '@/types';
import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformRequest } from './transformRequest';
import { transformSaveSurveyResponse } from '../utils/transformSaveSurveyResponse';
import { getFetchError } from '../utils/fetch';

export async function updateSurvey(survey: Survey) {
  const transformed = transformRequest(survey);
  const body = JSON.stringify(transformed);

  const request = createRequest(
    `${DEPOT_API_URL}/surveys/${survey.id}?include=move_task_order,move_task_order.orders,service,move_task_order.orders,move_task_order.orders.customer,move_task_order.orders.origin,move_task_order.orders.destination&fields[order]=number&fields[order.customer]=first_name,last_name`,
    {
      method: 'PATCH',
      body,
    }
  );

  const response = await fetch(request);
  const json = await response.json();

  if (response.ok) {
    return transformSaveSurveyResponse(json);
  } else {
    throw new Error(
      getFetchError(json.errors) ||
        'Failed to save survey due to an unknown error'
    );
  }
}
