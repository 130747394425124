import Dropzone from 'react-dropzone';
import { convertFileToBase64 } from '@/utils';
import { Stack, Typography, Link } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export function ImageDropzone({
  onUpload,
}: {
  onUpload: (files: Array<string>) => void;
}) {
  const handleAcceptedFiles = async (files: Array<File>) => {
    const convertToBase64Promises = files.map(convertFileToBase64);
    const base64Files = await Promise.all(convertToBase64Promises);
    onUpload(base64Files as Array<string>);
  };

  return (
    <Dropzone
      onDrop={handleAcceptedFiles}
      noClick={true}
      accept={{
        'image/png': ['.png'],
        'image/svg+xml': ['.svg'],
        'image/gif': ['.gif'],
        'image/jpeg': ['.jpg', '.jpeg'],
      }}
      maxSize={5000000}
    >
      {({ getRootProps, getInputProps, open }) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 1,
            height: 154,
            border: '1px dashed rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
          }}
          gap={1}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <UploadFileIcon sx={{ mb: 1 }} />
          <Typography variant="subtitle1">
            <Link onClick={open}>Click to upload</Link> or drag and drop
          </Typography>
          <Typography variant="subtitle2">
            SVG, PNG, JPG or GIF (max. 5MB)
          </Typography>
        </Stack>
      )}
    </Dropzone>
  );
}
