import { Room, RoomListItem } from '@/types';
import { OBJECT_METHOD } from '@/constants';
import { createObjectWithId } from './createObjectWithId';

export const findRoom = (rooms: Array<Room>, roomId: string) =>
  rooms.find(
    ({ id, _method }) => id === roomId && _method !== OBJECT_METHOD.DESTROY
  );

export const hasRoom = (rooms: Array<Room>, roomId: string) =>
  Boolean(findRoom(rooms, roomId));

export const updateRoom = (rooms: Array<Room>, room: Room) => {
  return rooms.map((existingRoom) => {
    if (existingRoom.id === room.id) {
      return {
        ...existingRoom,
        ...room,
        _method:
          existingRoom._method === OBJECT_METHOD.CREATE
            ? OBJECT_METHOD.CREATE
            : OBJECT_METHOD.UPDATE,
      };
    } else {
      return existingRoom;
    }
  });
};

export const addRoom = (rooms: Array<Room>, room: RoomListItem) => {
  return [
    ...rooms,
    createObjectWithId({
      name: room.classification,
      classification: room.classification,
      _method: OBJECT_METHOD.CREATE,
      images: [],
    }),
  ];
};

export const removeRoom = (rooms: Array<Room>, room: Room) => {
  return rooms
    .map((existingRoom) => {
      if (existingRoom.id === room.id) {
        return existingRoom._method === OBJECT_METHOD.CREATE
          ? null
          : {
              ...existingRoom,
              _method: OBJECT_METHOD.DESTROY,
            };
      } else {
        return existingRoom;
      }
    })
    .filter(Boolean) as Array<Room>;
};
