import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { WEIGHT_FACTOR } from '@/constants';
import { formatNumber } from '@/utils';
import { Totals } from '@/types';

export const TotalsDisplayItem = ({
  label,
  totals,
}: {
  label: string;
  totals: Totals;
}) => (
  <Stack direction="row" alignItems="center" gap="4px">
    <Typography variant="subtitle2" component="span">
      {label}:
    </Typography>
    <Typography variant="subtitle2" component="span" color="text.secondary">
      {formatNumber(totals.going)} items,
    </Typography>
    <Typography variant="subtitle2" component="span" color="text.secondary">
      {formatNumber(totals.cube)} cu ft,
    </Typography>
    <Typography variant="subtitle2" component="span" color="text.secondary">
      {formatNumber(totals.weight)} lbs
    </Typography>
  </Stack>
);

export const WeightFactorDisplayItem = ({
  weightFactor = WEIGHT_FACTOR,
}: {
  weightFactor?: number;
}) => (
  <Typography variant="subtitle2" component="span" color="secondary.main">
    Weight Factor: {weightFactor}
  </Typography>
);
