import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { GOING_NOT_GOING } from '@/constants';
import { GoingNotGoing } from '@/types';
import { useMediaMatches } from '@/hooks';

interface ToggleProps {
  value: GoingNotGoing;
  onChange: (value: GoingNotGoing) => void;
}

export function GoingNotGoingSelector({ value, onChange }: ToggleProps) {
  const matches = useMediaMatches();

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: GoingNotGoing
  ) => {
    if (value !== null) {
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      color="primary"
      onChange={handleChange}
      aria-label="Going or Not Going Selection"
    >
      {/* @ts-ignore: Due to variant not being supported yet */}
      <ToggleButton
        value={GOING_NOT_GOING.GOING}
        aria-label="Going"
        variant="green"
      >
        <Typography sx={{ whiteSpace: 'nowrap' }}>Going</Typography>
      </ToggleButton>

      {/* @ts-ignore: Due to variant not being supported yet */}
      <ToggleButton
        value={GOING_NOT_GOING.NOT_GOING}
        aria-label="Not Going"
        variant="red"
      >
        <Typography sx={{ whiteSpace: 'nowrap' }}>
          {matches.xs ? 'NG' : 'Not Going'}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
