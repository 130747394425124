import { ReactNode } from 'react';
import Container from '@mui/system/Container';
import { AppBar, CompletedOverlay } from '@/components';

const HEADER_HEIGHT = 64;
const XS_HEADER_HEIGHT = 56;

export function AppLayout(page: ReactNode) {
  return (
    <>
      <AppBar />
      <Container
        maxWidth="lg"
        sx={{
          height: {
            xs: `calc(100vh - ${XS_HEADER_HEIGHT}px)`,
            sm: `calc(100vh - ${HEADER_HEIGHT}px)`,
          },
          px: {
            xs: 0,
            md: 2,
          },
          py: {
            xs: 0,
            md: 3,
          },
          gap: 2,
        }}
      >
        {page}
      </Container>
    </>
  );
}
