import { ITEM_DEFINITIONS } from '@/constants';

interface IsCartonItemProps {
  name: string;
  [key: string]: unknown;
}

export const isCartonItem = (item: IsCartonItemProps) => {
  const definition = ITEM_DEFINITIONS.find(
    ({ name }: { name: string }) => name === item.name
  );
  return definition?.isCp || definition?.isPbo;
};
