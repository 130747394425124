import Head from 'next/head';
import { AppProps } from '@/types';
import { ThemeProvider } from '@/services/Theme/ThemeProvider';
import { StateProvider } from '@/services/StateProvider';
import { OnlineStatusProvider } from '@/services/OnlineStatusProvider';
import { AuthGuard } from '@/services/Auth/AuthGuard';
import { AppProvider } from '@/services/App/AppProvider';
import { Datadog } from '@/services/Datadog';
import { SnackbarProvider } from '@/components/common/Snackbar/SnackbarProvider';
import { Snackbar } from '@/components/common/Snackbar/Snackbar';
import { useEffect } from 'react';

export default function SurveyApp({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page);

  // Makes the app version available to query on the front end in the console.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.__HSC_SURVEY_VERSION_NUMBER =
        process.env.NEXT_PUBLIC_APP_VERSION || '';
    }
  }, []);

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <OnlineStatusProvider>
          <Datadog />
          <StateProvider>
            <AuthGuard>
              <AppProvider>
                <Head>
                  <title>HSC Survey</title>
                  <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                  />
                  <meta name="apple-mobile-web-app-capable" content="yes" />
                  <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="black-translucent"
                  />
                  <link rel="apple-touch-icon" href="images/app_icon.png" />
                </Head>
                <main>{getLayout(<Component {...pageProps} />)}</main>
              </AppProvider>
            </AuthGuard>
          </StateProvider>
        </OnlineStatusProvider>
        <Snackbar />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
