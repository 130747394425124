import Stack from '@mui/material/Stack';
import { TotalsDisplayItem, WeightFactorDisplayItem } from '@/components';
import { Totals } from '@/types';

interface SurveyTotalsFooterProps {
  segmentTotals: Totals;
  orderTotals: Totals;
  itemsTotals: Totals;
  proGearTotals: Totals;
  weightFactor: number;
}

export function SurveyTotalsFooter({
  segmentTotals,
  itemsTotals,
  proGearTotals,
  weightFactor,
  orderTotals,
}: SurveyTotalsFooterProps) {
  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      spacing={{ xs: 2 }}
      justifyContent="space-between"
    >
      <Stack direction={{ md: 'column', lg: 'row' }} gap={{ lg: 6 }}>
        <TotalsDisplayItem label="Segment" totals={segmentTotals} />
        <TotalsDisplayItem label="Order Total" totals={orderTotals} />
        <TotalsDisplayItem label="MTO Total" totals={itemsTotals} />
        <TotalsDisplayItem label="Pro Gear" totals={proGearTotals} />
      </Stack>
      <WeightFactorDisplayItem weightFactor={weightFactor} />
    </Stack>
  );
}
