export const SEGMENT_FROM = {
  MP: 'MP',
  XP1: 'XP1',
  XP2: 'XP2',
  XP3: 'XP3',
  XP4: 'XP4',
  XP5: 'XP5',
} as const;
export const SEGMENT_FROMS = Object.keys(SEGMENT_FROM);

export const SEGMENT_TO = {
  MD: 'MD',
  XD1: 'XD1',
  XD2: 'XD2',
} as const;
export const SEGMENT_TOS = Object.keys(SEGMENT_TO);

export const BASE_SEGMENT = {
  from: SEGMENT_FROM.MP,
  to: SEGMENT_TO.MD,
};
