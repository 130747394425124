import { hasFileStorage } from '@/constants';
import { promisifyWorker } from '@/utils';

export function createAsyncWorkerStorage<T>() {
  if (typeof Worker === 'undefined') return;

  const _worker = hasFileStorage
    ? new Worker(new URL('@/workers/fileStorageWorker', import.meta.url))
    : new Worker(new URL('@/workers/encryptedStorageWorker', import.meta.url));
  const worker = promisifyWorker<T>(_worker);

  return {
    async setItem(key: string, value: T) {
      await worker.postMessage({
        event: 'setItem',
        value,
        key,
      });
    },
    async getItem(key: string) {
      return await worker.postMessage({
        event: 'getItem',
        key,
      });
    },
    async removeItem(key: string) {
      await worker.postMessage({
        event: 'removeItem',
        key,
      });
    },
  };
}
