import { v1 as uuidv1 } from 'uuid';

interface MessageEventData {
  _messageId: string;
  success: boolean;
  value: unknown;
  error: string;
}

interface PromisifyWorker<T> {
  postMessage: (message: PostMessageProps) => Promise<T>;
}

interface PostMessageProps {
  event: string;
  value?: unknown;
  key?: string;
}

export const promisifyWorker = <T>(worker: Worker): PromisifyWorker<T> => {
  const messages = new Map();

  worker.addEventListener(
    'message',
    (message: MessageEvent<MessageEventData>) => {
      const { _messageId, success, error, value } = message.data;
      const [resolve, reject] = messages.get(_messageId);
      messages.delete(_messageId);
      if (success) {
        resolve(value);
      } else {
        reject(error);
      }
    }
  );

  const postMessage = <T>(message: PostMessageProps) => {
    return new Promise<T>((resolve, reject) => {
      const _messageId = uuidv1();
      messages.set(_messageId, [resolve, reject]);
      worker.postMessage({ _messageId, ...message });
    });
  };

  return {
    postMessage,
  };
};
