import { Item } from '@/types';

// Locates an existing duplicate item with a different ID
export const findExistingItem = (items: Array<Item>, item: Item) => {
  return items.find(
    (i) =>
      i.isoCode === item.isoCode &&
      i.name === item.name &&
      i.roomId === item.roomId &&
      i.segmentId === item.segmentId &&
      i.id !== item.id
  );
};
