import { Survey } from '@/types';
import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformRequest } from './transformRequest';
import { transformSaveSurveyResponse } from '../utils/transformSaveSurveyResponse';
import { getFetchError } from '../utils/fetch';

export async function createSurvey(survey: Survey) {
  const transformed = transformRequest(survey);
  const body = JSON.stringify(transformed);
  const request = createRequest(
    `${DEPOT_API_URL}/surveys?include=service,move_task_order,move_task_order.orders`,
    {
      method: 'POST',
      body,
    }
  );

  const response = await fetch(request);
  const json = await response.json();

  if (response.ok) {
    return transformSaveSurveyResponse(json);
  } else {
    throw new Error(
      getFetchError(json.errors) ||
        'Failed to create survey due to an unknown error'
    );
  }
}
