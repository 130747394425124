import Stack from '@mui/material/Stack';
import Container from '@mui/system/Container';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { Item } from '@/types';
import { useMediaMatches } from '@/hooks';
import { getTotals } from '@/utils';

type BoxColor = 'green' | 'red' | 'gray';

type SurveyTotalsProps = {
  header: string;
  value: string;
  color?: BoxColor;
};

type SurveyTotals = {
  items: Array<Item>;
};

const TotalsItem = ({ header, value, color = 'gray' }: SurveyTotalsProps) => {
  const colors = {
    green: '#E8F5E9',
    red: '#FEEBEE',
    gray: '#EEEEEE',
  };

  return (
    // min-width: 0 prevents overflow
    <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
      <ListItem sx={{ px: { xs: 0 } }}>
        <Container sx={{ px: { xs: 0 } }}>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {header}
          </Typography>
        </Container>
      </ListItem>
      <Chip
        label={value}
        size="small"
        sx={{ backgroundColor: colors[color] }}
      />
    </Stack>
  );
};

export function SurveyTotals({ items }: SurveyTotals) {
  const matches = useMediaMatches();
  const totals = getTotals(items);

  return (
    <Stack direction="row" width={1} gap={2}>
      <TotalsItem
        header="Going"
        value={matches.xs ? `${totals.going}` : `${totals.going} items`}
        color="green"
      />
      <TotalsItem
        header={matches.xs ? 'N. Going' : 'Not Going'}
        value={matches.xs ? `${totals.notGoing}` : `${totals.notGoing} items`}
        color="red"
      />
      <TotalsItem
        header="Weight"
        value={matches.xs ? `${totals.weight}` : `${totals.weight} lbs`}
      />
      <TotalsItem
        header="Cu. Ft"
        value={matches.xs ? `${totals.cube}` : `${totals.cube} cu. ft.`}
      />
    </Stack>
  );
}
