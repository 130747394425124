import { MouseEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export function BackLink() {
  const router = useRouter();

  const handleRouteToBack = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    router.back();
  };

  return (
    <Link
      href="#"
      onClick={handleRouteToBack}
      aria-label="Back Link"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ChevronLeftIcon sx={{ color: 'info.dark' }} />
    </Link>
  );
}
