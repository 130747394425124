import { Room, RoomListItem, Item } from '@/types';
import { RoomListEmpty } from './RoomListEmpty';
import { RoomListFull } from './RoomListFull';
import { filterDeletedItems } from '@/utils/filterDeletedItems';

interface RoomListProps {
  rooms: Array<Room>;
  items: Array<Item>;
  onAddRoom: (room: RoomListItem) => void;
  onRemoveRoom: (room: Room) => void;
  onRemoveRoomItems: (room: Room) => void;
  onEditRoom: (room: Room) => void;
}

export default function RoomList({
  rooms = [],
  items = [],
  onAddRoom,
  onRemoveRoom,
  onRemoveRoomItems,
  onEditRoom,
}: RoomListProps) {
  const roomsToDisplay = filterDeletedItems(rooms);

  return roomsToDisplay.length ? (
    <RoomListFull
      rooms={roomsToDisplay}
      items={items}
      onRemoveRoom={onRemoveRoom}
      onRemoveRoomItems={onRemoveRoomItems}
      onEditRoom={onEditRoom}
    />
  ) : (
    <RoomListEmpty onAddRoom={onAddRoom} />
  );
}
