import { colors, Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiContainer: {
    styleOverrides: {
      maxWidthLg: ({ theme }: { theme: Theme }) => ({
        [theme.breakpoints.up('lg')]: {
          maxWidth: '1800px',
        },
      }),
    },
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'teal' },
        style: {
          backgroundColor: colors.teal[50],
          border: `1px solid ${colors.teal[900]}`,
          color: colors.teal[900],
        },
      },
      {
        props: { variant: 'deepPurple' },
        style: {
          backgroundColor: colors.deepPurple[50],
          border: `1px solid ${colors.deepPurple[900]}`,
          color: colors.deepPurple[900],
        },
      },
    ],
  },
  MuiToggleButton: {
    variants: [
      {
        // @ts-ignore: This works, just not in TypeScript (see theme-types.ts)
        props: { variant: 'teal' },
        style: {
          '&.Mui-selected': {
            backgroundColor: colors.teal[50],
            color: colors.teal[900],
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.teal[100],
          },
        },
      },
      {
        // @ts-ignore: This works, just not in TypeScript (see theme-types.ts)
        props: { variant: 'deepPurple' },
        style: {
          '&.Mui-selected': {
            backgroundColor: colors.deepPurple[50],
            color: colors.deepPurple[900],
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.deepPurple[100],
          },
        },
      },
      {
        // @ts-ignore: This works, just not in TypeScript (see theme-types.ts)
        props: { variant: 'green' },
        style: {
          '&.Mui-selected': {
            backgroundColor: colors.green[100],
            color: colors.green[900],
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.green[200],
          },
        },
      },
      {
        // @ts-ignore: This works, just not in TypeScript (see theme-types.ts)
        props: { variant: 'red' },
        style: {
          '&.Mui-selected': {
            backgroundColor: colors.red[100],
            color: colors.red[900],
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.red[200],
          },
        },
      },
    ],
  },
};
