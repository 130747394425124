import { OBJECT_METHOD } from '@/constants';
import { calculateWeightFromCube } from '../weight';
interface TotalsItem {
  going: number;
  notGoing: number;
  weight: number | null;
  cube: number;
  [key: string]: unknown;
}

const toFixedNumber = (num: number) => Number(num.toFixed(2));
const toRounded = Math.round;

export const getTotals = (items: Array<TotalsItem>) => {
  const totals = items
    .filter(({ _method }) => _method !== OBJECT_METHOD.DESTROY)
    .reduce(
      (total, item) => ({
        going: total.going + item.going,
        notGoing: total.notGoing + item.notGoing,
        weight:
          (total.weight || 0) +
          item.going *
            (item.weight === null || item.weight === undefined
              ? calculateWeightFromCube(item.cube)
              : item.weight),
        cube: total.cube + item.cube * item.going,
      }),
      {
        going: 0,
        notGoing: 0,
        weight: 0,
        cube: 0,
      }
    );

  return {
    going: toFixedNumber(totals.going),
    notGoing: toFixedNumber(totals.notGoing),
    weight: toRounded(totals.weight || 0),
    cube: toFixedNumber(totals.cube),
  };
};
