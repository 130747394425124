export const FETCH_ERROR_CODES = {
  NOT_FOUND: 'not_found',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
};

export const getFetchError = (
  errors: Array<{} & { code: string; detail: string }>
) => {
  if (!errors || errors.length < 1) {
    return null;
  }

  const error = errors[0];
  switch (error.code) {
    case FETCH_ERROR_CODES.NOT_FOUND:
      return `Survey could not be found.`;
    case FETCH_ERROR_CODES.UNAUTHORIZED:
      return `Your authorization token has expired. Please sign back into CRM.`;
    case FETCH_ERROR_CODES.FORBIDDEN:
      return 'You do not have permission to save this survey';
    default:
      return error.detail;
  }
};
