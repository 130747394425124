import { BASE_SEGMENT } from '@/constants';
import { Order, Segment } from '@/types';
import { createSegments } from './segment';

interface CreateEmptySurveyProps {
  moveTaskOrderId: string;
  orders: Array<Order>;
  serviceId: string;
  surveyType: string;
}

export const createEmptySurvey = ({
  moveTaskOrderId,
  orders,
  serviceId,
  surveyType,
}: CreateEmptySurveyProps): {
  completed: boolean;
  items: any[];
  moveTaskOrderId: string;
  rooms: any[];
  segments: Segment[];
  serviceId: string;
  surveyType: string;
} => {
  return {
    completed: false,
    items: [],
    moveTaskOrderId,
    rooms: [],
    segments: createSegments(BASE_SEGMENT, orders),
    serviceId,
    surveyType,
  };
};
