import { useState } from 'react';
import { Room } from '@/types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, OutlinedInput, Typography } from '@mui/material';

interface RoomSelectProps {
  labelId?: string;
  rooms: Array<Room>;
  selectedRoomId: string;
  onChange: (roomId: string) => void;
}

export function RoomSelect({
  labelId = 'room-select',
  rooms,
  selectedRoomId,
  onChange,
}: RoomSelectProps) {
  const [selectedRoom, setSelectedRoom] = useState<string>(selectedRoomId);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newRoomId = event.target.value as string;
    setSelectedRoom(newRoomId);
    onChange(newRoomId);
  };

  return (
    <Select
      id="room-name"
      value={selectedRoom}
      onChange={handleChange}
      inputProps={{ id: labelId }}
    >
      {rooms.map((room: Room) => {
        return (
          <MenuItem key={`${room.id}`} value={room.id}>
            <Typography>{room.name}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
}
