import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const load = keyframes`
  0% {
    transform: translateY(-105px) scale(1);
    opacity: 0;
  }
  4.5%,
  7.5% {
    transform: translateY(-115px) scale(1);
    opacity: 1;
  }
  15% {
    transform: translateY(0) scale(0.44);
  }
`;

export default styled.div`
  position: relative;
  width: 90px;
  height: 125px;
  place-self: center;

  .top,
  .bottom {
    position: absolute;
    bottom: 0;
  }

  .top {
    bottom: 38.5px;
    z-index: 1;
  }
  .bottom {
    z-index: 3;
  }

  .loader__item {
    position: absolute;
    top: 49px;
    left: -4px;
    transform: scale(0.44);
    animation: ${load} 7.5s ease-out 0s infinite;
    z-index: 1;
    will-change: transform;
  }

  .loader__bed {
    animation-delay: 0s;
  }
  .loader__mirror {
    animation-delay: 1.5s;
  }
  .loader__lamp {
    animation-delay: 3s;
  }
  .loader__desk {
    animation-delay: 4.5s;
  }
  .loader__couch {
    animation-delay: 6s;
  }
`;
