import {
  Stack,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Button,
  CardActions,
} from '@mui/material';
import { SurveyCRMLink } from './SurveyCRMLink';

export function CompletedOverlay({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog open={open}>
      <Stack width={1} height={1} justifyContent="center" alignItems="center">
        <Card
          sx={{
            p: { xs: 1, sm: 2 },
            whiteSpace: 'nowrap',
          }}
        >
          <CardHeader sx={{ p: 2 }} title="Your survey is complete" />
          <CardContent sx={{ textAlign: 'center', p: { xs: 1, sm: 2 } }}>
            <SurveyCRMLink>Open in HomeSafe Connect</SurveyCRMLink>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={onClose}>Close</Button>
          </CardActions>
        </Card>
      </Stack>
    </Dialog>
  );
}
