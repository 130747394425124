import Loader from './Loader';
import Overlay from './Overlay';

import boxTop from './assets/box-top.svg';
import boxBottom from './assets/box-bottom.svg';
import bed from './assets/bed.svg';
import mirror from './assets/mirror.svg';
import lamp from './assets/lamp.svg';
import desk from './assets/desk.svg';
import couch from './assets/couch.svg';

export function LoadingDisplay() {
  return (
    <Overlay>
      <Loader>
        <img src={boxTop.src} className="top" alt="Box Top Icon" />
        <img src={boxBottom.src} className="bottom" alt="Box Bottom Icon" />
        <img
          src={bed.src}
          className="loader__item loader__bed"
          alt="Bed Icon"
        />
        <img
          src={mirror.src}
          className="loader__item loader__mirror"
          alt="Mirror Icon"
        />
        <img
          src={lamp.src}
          className="loader__item loader__lamp"
          alt="Lamp Icon"
        />
        <img
          src={desk.src}
          className="loader__item loader__desk"
          alt="Desk Icon"
        />
        <img
          src={couch.src}
          className="loader__item loader__couch"
          alt="Couch Icon"
        />
      </Loader>
    </Overlay>
  );
}
