export const ITEM_CATEGORY = {
  HOUSEHOLD_GOODS: 'HOUSEHOLD_GOODS',
  MEMBER_PRO_GEAR: 'MEMBER_PROGEAR',
  SPOUSE_PRO_GEAR: 'SPOUSE_PROGEAR',
} as const;

export const ITEM_CATEGORIES = Object.values(ITEM_CATEGORY);

export const PRO_GEAR_CATEGORIES = [
  ITEM_CATEGORY.MEMBER_PRO_GEAR,
  ITEM_CATEGORY.SPOUSE_PRO_GEAR,
] as const;
