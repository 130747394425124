import { ReactNode } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button, { ButtonProps } from '@mui/material/Button';
import AddRoomMenu from './AddRoomMenu';
import { RoomListItem } from '@/types';
import { usePopupState } from '@/hooks';

interface AddRoomButtonProps extends ButtonProps {
  onAddRoom: (room: RoomListItem) => void;
  children: ReactNode;
}

export function AddRoomButton({
  variant = 'contained',
  color,
  onAddRoom,
  children,
}: AddRoomButtonProps) {
  const { popupAnchorEl, isPopupOpen, openPopup, closePopup } = usePopupState();

  const handleOnAddRoom = (room: RoomListItem) => {
    closePopup();
    onAddRoom(room);
  };

  return (
    <>
      <Button
        onClick={openPopup}
        color={color}
        variant={variant}
        startIcon={<AddIcon />}
      >
        {children}
      </Button>
      <AddRoomMenu
        anchorEl={popupAnchorEl}
        open={isPopupOpen}
        onClose={closePopup}
        onSelectRoom={handleOnAddRoom}
      />
    </>
  );
}
