import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { RESET } from 'jotai/utils';
import { LoadingDisplay } from '@/components';
import { APP_STATES, APP_STORAGE_KEY } from '@/constants/app';
import { createEmptySurvey } from '@/utils/createEmptySurvey';
import { getQueryParam } from '@/utils/getQueryParam';
import { useGetFromAsyncStorage } from '@/hooks';
import { fetchSurveyService } from '@/api';
import { fetchSurvey } from '@/api/fetchSurvey/fetchSurvey';
import { fetchSurveysByServiceId } from '@/api/fetchSurvey/fetchSurveysByServiceId';
import { App } from '@/types/App';
import { useApp } from '@/hooks/useApp';
import { NotFound } from './NotFound';
import { LoadingError } from './LoadingError';
import { softReplaceUrlParams } from '@/utils/softReplaceUrlParams';

const ROUTES_TO_IGNORE = ['/demo'];

let isProcessing = false;

export function AppProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const ignoreRoute = ROUTES_TO_IGNORE.includes(router.asPath);
  const [app, setApp] = useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<false | string>(false);
  const getCachedAppState = useGetFromAsyncStorage<App>(APP_STORAGE_KEY);

  const handleLoadApp = () => {
    if (isProcessing) return;
    isProcessing = true;

    const loadSurveyService = async () => {
      try {
        const cached = await getCachedAppState();
        const surveyIdFromPath = getQueryParam('surveyId');
        const serviceIdFromPath = getQueryParam('serviceId');

        if (surveyIdFromPath) {
          // Check if we already have the survey with that ID in the cache
          if (cached?.survey?.id === surveyIdFromPath) {
            setApp(cached);

            //  Otherwise, fetch the survey and set the state to edit mode
          } else {
            const fromNetwork = await fetchSurvey(surveyIdFromPath);
            if (fromNetwork?.survey) {
              setApp({
                ...fromNetwork,
                state: APP_STATES.EDITING,
              } as App);
            } else {
              setLoadingError(`Error fetching Survey ${surveyIdFromPath}`);
            }
          }
        } else if (serviceIdFromPath) {
          // Check if we already have the service with that ID in the cache
          if (cached?.service?.id === serviceIdFromPath) {
            if (cached) setApp(cached);
            // Otherwise, fetch the service and set the state accordingly
          } else {
            const fromNetwork = await fetchSurveyService(serviceIdFromPath);
            if (fromNetwork?.service) {
              const { service, move_task_order } = fromNetwork;
              // If the service is already performed, then get the survey and put the app into edit mode
              if (service.completionStatus === 'PERFORMED') {
                const response = await fetchSurveysByServiceId(service.id);
                setApp({
                  ...response,
                  state: APP_STATES.EDITING,
                  service,
                } as App);

                // If the service is not performed, then create a new, empty survey and put the app into create mode
              } else {
                setApp({
                  ...fromNetwork,
                  state: APP_STATES.CREATING,
                  survey: createEmptySurvey({
                    moveTaskOrderId: move_task_order.id,
                    serviceId: service.id,
                    surveyType: service.subtype,
                    orders: move_task_order.orders,
                  }),
                } as App);
              }
            } else {
              setLoadingError(
                `Error fetching Survey Service ${serviceIdFromPath}`
              );
            }
          }

          // No IDs found, so use the cache if we have it
        } else if (cached) {
          setApp(cached);
          // No survey or service found
        } else {
          console.warn('No survey or service found');
          setApp(RESET);
        }
      } catch (error) {
        console.error('Error loading survey or service:', error);
        setLoadingError('Error loading survey or service');
      } finally {
        setIsLoading(false);
      }
    };

    loadSurveyService();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleLoadApp, []);

  useEffect(() => {
    // We want to clean up the querystring params, but the router.query, which
    // softReplaceUrlParams relies on isn't populated until router.isReady, then
    // we have to wait for it, but we don't want to do that until we're done loading.
    if (!isLoading && router.isReady) {
      softReplaceUrlParams(router, ['serviceId', 'surveyId']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isLoading]);

  if (ignoreRoute) {
    return <>{children}</>;
  } else if (isLoading) {
    return <LoadingDisplay />;
  } else if (loadingError) {
    return <LoadingError />;
  } else if (!app.service?.id && !app.survey?.id) {
    return <NotFound />;
  } else {
    return <>{children}</>;
  }
}
