import Tooltip from '@mui/material/Tooltip';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import { useOnlineStatus } from '@/hooks';

export function WifiIndicator() {
  const isOnline = useOnlineStatus();

  return (
    <Tooltip title={isOnline ? 'Online' : 'Offline'}>
      {isOnline ? <WifiIcon /> : <WifiOffIcon />}
    </Tooltip>
  );
}
