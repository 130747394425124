import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextButton } from '@/components';
import { Room } from '@/types';
import Stack from '@mui/material/Stack';
import { getTotals } from '@/utils';
import { useSurvey } from '@/hooks/useSurvey';
import { useAtom } from 'jotai';
import { selectedSegmentIdAtom } from '@/store';

interface ConfirmRemoveRoomItemsModalProps {
  open: boolean;
  room: Room;
  onCancel: () => void;
  onConfirm: (room: Room) => void;
}

export default function ConfirmRemoveRoomItemsModal({
  open,
  room,
  onCancel,
  onConfirm,
}: ConfirmRemoveRoomItemsModalProps) {
  const { items } = useSurvey();
  const [selectedSegmentId] = useAtom(selectedSegmentIdAtom);
  const roomItems = items.filter(
    (item) => item.segmentId === selectedSegmentId && item.roomId === room.id
  );
  const { going } = getTotals(roomItems);

  return (
    <Dialog open={open}>
      <DialogTitle>Remove Room Items</DialogTitle>

      <DialogContent>
        Are you sure you would like to remove all items in this room for this segment?
        {going > 0 ? (
          <Stack direction="row" justifyContent="center" marginTop={2}>
            <strong>
              {going} {going === 1 ? 'item' : 'items'} will be removed.
            </strong>
          </Stack>
        ) : null}
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onCancel}>Cancel</TextButton>
        <TextButton color="error" onClick={() => onConfirm(room)}>
          Remove All Items
        </TextButton>
      </DialogActions>
    </Dialog>
  );
}
