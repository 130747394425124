export function CloseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <rect
        width="32"
        height="32"
        y="0.941"
        fill="url(#paint0_linear_2668_48052)"
        rx="16"
      />
      <path
        fill="#585859"
        d="M22.222 11.972l-1.254-1.253L16 15.688l-4.97-4.97-1.253 1.254 4.97 4.969-4.97 4.969 1.254 1.253L16 18.194l4.968 4.97 1.254-1.254-4.97-4.969 4.97-4.969z"
      />
      <rect
        width="30.667"
        height="30.667"
        x="0.667"
        y="1.608"
        stroke="#666667"
        strokeOpacity="0.51"
        strokeWidth="1.333"
        rx="15.333"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2668_48052"
          x1="31.832"
          x2="-7.025"
          y1="32.942"
          y2="32.864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFB" />
          <stop offset="1" stopColor="#F5FBFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
