import { Segment } from '@/types';
import styled from '@emotion/styled';

interface SegmentOrderNumberProps {
  segment: Segment;
  className?: 'dark' | 'normal';
}

const NormalComponent = styled.div`
  color: 'rgba(0, 0, 0, 0.4)';
  font-weight: 400;
`;

const DarkComponent = styled.div`
  color: 'black';
  font-weight: 400;
`;

export function SegmentOrderNumber({
  segment,
  className = 'normal',
}: SegmentOrderNumberProps) {
  return (
    <>
      {className === 'normal' && (
        <NormalComponent className={'segOrdNum'}>
          Order: {segment.orderNumber}
        </NormalComponent>
      )}
      {className === 'dark' && (
        <DarkComponent className={'segOrdNum'}>
          Order {segment.orderNumber}
        </DarkComponent>
      )}
    </>
  );
}
