import { CRM_API_URL } from '../constants';

export async function fetchRefreshToken() {
  try {
    await fetch(`${CRM_API_URL}/index.php?module=Users&view=Profile`, {
      credentials: 'include',
      mode: 'no-cors',
    });
  } catch (e) {
    console.error('Failed to refresh token:', e);
  }
}
