import { ChangeEvent, SetStateAction } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LoopIcon from '@mui/icons-material/Loop';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import {
  filterByIncludes,
  filterByStartsWith,
  segmentItemDefinitions,
  upsertItemWithItemDefinition,
} from '@/utils';
import { GOING_NOT_GOING, ITEM_CATEGORY, ITEM_DEFINITIONS } from '@/constants';
import {
  ItemCategory,
  ItemDefinition,
  GoingNotGoing,
  Room,
  Item,
} from '@/types';
import { selectedSegmentIdAtom, routeRoomAtom, itemsAtom } from '@/store';

import {
  ProGearSelector,
  GoingNotGoingSelector,
  ItemDefinitionList,
  LetterFilter,
} from './components';
import { TabPanel } from '@/components';
import { useMediaMatches } from '@/hooks';
import { List } from '@mui/material';

const LIST_VALUES = {
  ROOM: 'room',
  ALL: 'all',
  CP: 'cp',
  PBO: 'pbo',
};

const categoryAtom = atom<ItemCategory>(ITEM_CATEGORY.HOUSEHOLD_GOODS);
const goingNotGoingAtom = atom<GoingNotGoing>(GOING_NOT_GOING.GOING);
const firstListTabAtom = atom(LIST_VALUES.ROOM);
const secondListTabAtom = atom(LIST_VALUES.CP);
const letterFilterAtom = atom('');
const textFieldSearchAtom = atom('');

interface ItemDefinitionsCardProps {
  onShowItems: () => void;
}

export function ItemDefinitionsCard({ onShowItems }: ItemDefinitionsCardProps) {
  const room = useAtomValue(routeRoomAtom);
  const setItems = useSetAtom(itemsAtom);
  const matches = useMediaMatches();

  const [selectedSegment] = useAtom(selectedSegmentIdAtom);
  const [category, setCategory] = useAtom(categoryAtom);
  const [goingNotGoing, setGoingNotGoing] = useAtom(goingNotGoingAtom);
  const [firstListTabValue, setFirstListValue] = useAtom(firstListTabAtom);
  const [secondListTabValue, setSecondListValue] = useAtom(secondListTabAtom);

  const [letterFilter, setLetterFilter] = useAtom(letterFilterAtom);
  const [textFieldSearch, setTextFieldSearch] = useAtom(textFieldSearchAtom);

  const filteredItemDefinitions = ITEM_DEFINITIONS.filter(
    filterByStartsWith(letterFilter)
  ).filter(filterByIncludes(textFieldSearch));

  const filteredItemsDefinitions = segmentItemDefinitions(
    room as Room,
    filteredItemDefinitions
  );

  const handleAddItemToRoom = (itemDefinition: ItemDefinition) => {
    if (!room) {
      return;
    }

    setItems((prevItems: Array<Item>) =>
      upsertItemWithItemDefinition(prevItems, {
        name: itemDefinition.name,
        weight: itemDefinition.weight,
        cube: itemDefinition.cube,
        isoCode: itemDefinition.isoCode,
        roomId: room?.id as string,
        segmentId: selectedSegment,
        category,
        going: Number(goingNotGoing === GOING_NOT_GOING.GOING),
        notGoing: Number(goingNotGoing === GOING_NOT_GOING.NOT_GOING),
        definitionId: itemDefinition.id
      })
    );
  };

  const handleProGearSelected = (newCategory: ItemCategory) => {
    setCategory(newCategory);
  };

  const handleGoingNotGoingChange = (value: GoingNotGoing) => {
    setGoingNotGoing(value);
  };

  const handleLetterFilterClick = (letter: string) => {
    setTextFieldSearch('');

    const isAlreadyCurrentFilter = letterFilter === letter;
    setLetterFilter(isAlreadyCurrentFilter ? '' : letter);
  };

  const handleTextFieldSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextFieldSearch(value);
    setLetterFilter('');
  };

  const handleListTabValueChange =
    (setListStateAction: (update: SetStateAction<string>) => void) =>
    (_event: React.SyntheticEvent, newValue: string) => {
      setListStateAction(newValue);
    };

  const resetAll = () => {
    setGoingNotGoing(GOING_NOT_GOING.GOING);
    setCategory(ITEM_CATEGORY.HOUSEHOLD_GOODS);
    setTextFieldSearch('');
    setLetterFilter('');
  };

  return (
    <Card sx={{ height: 1 }}>
      <Paper sx={{ height: 1 }}>
        <CardContent sx={{ height: 1, p: { xs: 0, sm: 2 } }}>
          <List disablePadding>
            <ListItem divider sx={{ px: { xs: 2, sm: 0 } }}>
              <Stack direction="row" alignItems="center" gap={2}>
                {matches.lgDown && (
                  <IconButton onClick={onShowItems} sx={{ px: 0 }}>
                    <ChevronLeftIcon />
                  </IconButton>
                )}
                <Typography variant="h6" component="h2">
                  Add Items to Room
                </Typography>
              </Stack>
            </ListItem>

            <ListItem divider sx={{ backgroundColor: '#FBFBFB' }}>
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="center"
                width={1}
              >
                <GoingNotGoingSelector
                  value={goingNotGoing}
                  onChange={handleGoingNotGoingChange}
                />

                <ProGearSelector
                  value={category}
                  onChange={handleProGearSelected}
                />

                <IconButton
                  color="primary"
                  aria-label="Reset All"
                  component="label"
                  onClick={resetAll}
                >
                  <LoopIcon />
                </IconButton>

                {matches.smUp ? (
                  <TextField
                    id="search-items"
                    label="Filter items by name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={textFieldSearch}
                    onChange={handleTextFieldSearch}
                    sx={{ flexGrow: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
              </Stack>
            </ListItem>

            <ListItem disablePadding>
              <Stack direction="row" width={1} justifyContent="space-around">
                {matches.xlDown ? (
                  <Tabs
                    value={firstListTabValue}
                    onChange={handleListTabValueChange(setFirstListValue)}
                    sx={{ width: 1 }}
                  >
                    <Tab
                      value={LIST_VALUES.ROOM}
                      label="Room"
                      sx={{ flexGrow: 1 }}
                    />
                    <Tab
                      value={LIST_VALUES.ALL}
                      label="All"
                      sx={{ flexGrow: 1 }}
                    />
                    <Tab
                      value={LIST_VALUES.CP}
                      label="CP"
                      sx={{ flexGrow: 1 }}
                    />
                    <Tab
                      value={LIST_VALUES.PBO}
                      label="PBO"
                      sx={{ flexGrow: 1 }}
                    />
                  </Tabs>
                ) : (
                  <>
                    <Tabs
                      value={firstListTabValue}
                      onChange={handleListTabValueChange(setFirstListValue)}
                      sx={{ width: 1 }}
                    >
                      <Tab
                        value={LIST_VALUES.ROOM}
                        label="Room"
                        sx={{ flexGrow: 1 }}
                      />
                      <Tab
                        value={LIST_VALUES.ALL}
                        label="All"
                        sx={{ flexGrow: 1 }}
                      />
                    </Tabs>

                    <Tabs
                      value={secondListTabValue}
                      onChange={handleListTabValueChange(setSecondListValue)}
                      sx={{ width: 1 }}
                    >
                      <Tab
                        value={LIST_VALUES.CP}
                        label="CP"
                        sx={{ flexGrow: 1 }}
                      />
                      <Tab
                        value={LIST_VALUES.PBO}
                        label="PBO"
                        sx={{ flexGrow: 1 }}
                      />
                    </Tabs>
                  </>
                )}
              </Stack>
            </ListItem>

            <ListItem disablePadding>
              <LetterFilter
                value={letterFilter}
                onClick={handleLetterFilterClick}
              />
            </ListItem>
          </List>

          {matches.xlDown ? (
            <Stack width={1} height={1}>
              <TabPanel
                value={firstListTabValue}
                id={LIST_VALUES.ROOM}
                style={{ height: '100%' }}
              >
                <ItemDefinitionList
                  title="Room Items"
                  itemDefinitions={filteredItemsDefinitions.room}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                value={firstListTabValue}
                id={LIST_VALUES.ALL}
                style={{ height: '100%' }}
              >
                <ItemDefinitionList
                  title="All Items"
                  itemDefinitions={filteredItemsDefinitions.all}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                value={firstListTabValue}
                id={LIST_VALUES.CP}
                style={{ height: '100%' }}
              >
                <ItemDefinitionList
                  title="Carton Items"
                  itemDefinitions={filteredItemsDefinitions.cp}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                value={firstListTabValue}
                id={LIST_VALUES.PBO}
                style={{ height: '100%' }}
              >
                <ItemDefinitionList
                  title="PBO Items"
                  itemDefinitions={filteredItemsDefinitions.pbo}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>
            </Stack>
          ) : (
            <Stack direction="row" width={1} height={1}>
              <TabPanel
                style={{ flex: 1, height: '100%' }}
                value={firstListTabValue}
                id={LIST_VALUES.ROOM}
              >
                <ItemDefinitionList
                  title="Room Items"
                  itemDefinitions={filteredItemsDefinitions.room}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                style={{ flex: 1, height: '100%' }}
                value={firstListTabValue}
                id={LIST_VALUES.ALL}
              >
                <ItemDefinitionList
                  title="All Items"
                  itemDefinitions={filteredItemsDefinitions.all}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                style={{ flex: 1, height: '100%' }}
                value={secondListTabValue}
                id={LIST_VALUES.CP}
              >
                <ItemDefinitionList
                  title="Carton Items"
                  itemDefinitions={filteredItemsDefinitions.cp}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>

              <TabPanel
                style={{ flex: 1, height: '100%' }}
                value={secondListTabValue}
                id={LIST_VALUES.PBO}
              >
                <ItemDefinitionList
                  title="PBO Items"
                  itemDefinitions={filteredItemsDefinitions.pbo}
                  onClick={handleAddItemToRoom}
                />
              </TabPanel>
            </Stack>
          )}
        </CardContent>
      </Paper>
    </Card>
  );
}
