import { get, set, del } from 'idb-keyval';

export const AsyncStorage = {
  async getItem<T>(key: string): Promise<T | null> {
    try {
      const response = await get(key);
      return response ?? null;
    } catch (err) {
      console.warn('[AsyncStorage.getItem] Error:', err);
      return null;
    }
  },
  async setItem<T>(key: string, value: T): Promise<void> {
    try {
      await set(key, value);
    } catch (err) {
      console.warn('[AsyncStorage.setItem] Error:', err);
    }
  },
  async removeItem(key: string): Promise<void> {
    try {
      await del(key);
    } catch (err) {
      console.warn('[AsyncStorage.removeItem] Error:', err);
    }
  },
};
