import { useState, MouseEvent } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';

import { Room, Item } from '@/types';
import { removeItemsInRoomSegment, updateRoom } from '@/utils';
import {
  AddButton,
  TotalsDisplayItem,
  RoomActionMenu,
  WeightFactorDisplayItem,
  EditRoomModal,
} from '@/components';
import { roomsAtom, routeRoomAtom, selectedSegmentIdAtom } from '@/store';
import { useRoomItems, useMediaMatches, useSurvey } from '@/hooks';

import { BackLink } from '../BackLink';
import { RoomItemsList } from './components';
import ConfirmRemoveRoomItemsModal from '@/components/pages/SurveyPage/components/RoomList/ConfirmRemoveRoomItemsModal';

interface RoomItemsCardProps {
  onAddItems: () => void;
}

export function RoomItemsCard({ onAddItems }: RoomItemsCardProps) {
  const { setItems } = useSurvey();
  const matches = useMediaMatches();
  const room = useAtomValue(routeRoomAtom);
  const setRooms = useSetAtom(roomsAtom);
  const { roomTotals, itemTotals, proGearTotals, segmentedItems } =
    useRoomItems(room?.id as string);
  const [selectedSegmentId] = useAtom(selectedSegmentIdAtom);

  const [actionState, setActionState] = useState<{
    actionAnchorEl: HTMLElement | null;
    isActionOpen: boolean;
    isEditRoomOpen: boolean;
    isConfirmRemoveItemsModalOpen: boolean;
    item: Item | null;
  }>({
    actionAnchorEl: null,
    isActionOpen: false,
    isEditRoomOpen: false,
    isConfirmRemoveItemsModalOpen: false,
    item: null,
  });

  const onEditRoom = (room: Room) => {
    closeModal('isEditRoomOpen');
    setRooms((prevRooms: Array<Room>) => updateRoom(prevRooms, room));
  };

  const onConfirmRemoveRoomItems = (room: Room) => {
    setItems((prevItems) =>
      removeItemsInRoomSegment(prevItems, selectedSegmentId, room.id)
    );
    closeModal('isConfirmRemoveItemsModalOpen');
  };

  const onActionClose = () => {
    setActionState({
      ...actionState,
      actionAnchorEl: null,
      isActionOpen: false,
    });
  };

  const onActionButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setActionState({
      ...actionState,
      actionAnchorEl: event.currentTarget,
      isActionOpen: true,
    });
  };

  const openModal = (modal: string) => {
    setActionState({
      ...actionState,
      isActionOpen: false,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setActionState({
      ...actionState,
      [modal]: false,
    });
  };

  return (
    <Card sx={{ height: 1 }}>
      <CardContent
        sx={{
          height: 1,
          display: 'grid',
          gridTemplateRows: 'min-content auto min-content',
          padding: {
            xs: 0,
            sm: 2,
          },
          '&:last-child': {
            pb: {
              xs: 1,
              sm: 2,
            },
          },
        }}
      >
        <List disablePadding>
          <ListItem sx={{ px: 0 }}>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              width={1}
              px={{ xs: 2, sm: 0 }}
            >
              <BackLink />
              <Typography variant="h6" component="h2">
                {room?.name || 'Unknown Room'}
              </Typography>

              <CardActions sx={{ marginLeft: 'auto', pr: 0 }}>
                {matches.lgDown && (
                  <AddButton
                    data-testid="AddFirstRoom"
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={onAddItems}
                  >
                    {matches.xs ? 'Items' : 'Add Items'}
                  </AddButton>
                )}

                {matches.smUp && (
                  <IconButton onClick={onActionButtonClick} disabled={!room}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </CardActions>
            </Stack>
          </ListItem>
        </List>

        <Box
          sx={{
            height: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            gap: 2,
          }}
        >
          <RoomItemsList
            title="Items"
            items={segmentedItems.room}
            emptyText="No Items"
          />
          <RoomItemsList
            title="Cartons"
            items={segmentedItems.carton}
            emptyText="No Cartons"
          />
        </Box>
        <List sx={{ px: { xs: 2, sm: 0 }, pb: 0 }}>
          <ListItem disablePadding>
            <TotalsDisplayItem label="Room" totals={roomTotals} />
          </ListItem>
          <ListItem disablePadding>
            <TotalsDisplayItem label="Total" totals={itemTotals} />
          </ListItem>
          <ListItem disablePadding>
            <TotalsDisplayItem label="Pro Gear" totals={proGearTotals} />
          </ListItem>
          <ListItem disablePadding sx={{ pt: 3 }}>
            <WeightFactorDisplayItem />
          </ListItem>
        </List>
      </CardContent>

      <RoomActionMenu
        anchorEl={actionState.actionAnchorEl}
        open={actionState.isActionOpen}
        onClose={onActionClose}
        onEditRoom={() => openModal('isEditRoomOpen')}
        onRemoveRoomItems={() => openModal('isConfirmRemoveItemsModalOpen')}
      />

      {actionState.isEditRoomOpen ? (
        <EditRoomModal
          open={actionState.isEditRoomOpen}
          room={room as Room}
          onClose={() => closeModal('isEditRoomOpen')}
          onChange={onEditRoom}
        />
      ) : null}
      {actionState.isConfirmRemoveItemsModalOpen ? (
        <ConfirmRemoveRoomItemsModal
          open={actionState.isConfirmRemoveItemsModalOpen}
          room={room as Room}
          onCancel={() => closeModal('isConfirmRemoveItemsModalOpen')}
          onConfirm={onConfirmRemoveRoomItems}
        />
      ) : null}
    </Card>
  );
}
