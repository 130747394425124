import { useEffect } from 'react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { useOnlineStatus } from '@/hooks';

async function startDatadog() {
  const dataDogInit: RumInitConfiguration = {
    applicationId: 'fd5a7b40-803a-4caf-9b5f-a14daeb21ebe',
    clientToken: 'pubb83966d4a056b1521cfe2aa3b62287c9',
    site: 'ddog-gov.com',
    service: process.env.DATADOG_SERVICE,
    env: process.env.NEXT_PUBLIC_APPLICATION_ENV ?? 'dev',
    version: process.env.APP_VERSION,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/api.*\.homesafeconnect\.com/],
  };

  try {
    datadogRum.init(dataDogInit);
    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty('head_git_sha', process.env.HEAD_SHA);
  } catch (error) {
    console.error('Datadog not started, missing configuration.', error);
  }
}

/**
 * Service which bootstraps Datadog RUM (Real User Monitoring)
 * @returns null
 */
export function Datadog() {
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (isOnline && process.env.NODE_ENV === 'production') {
      startDatadog();
    }
  }, [isOnline]);
  return null;
}
