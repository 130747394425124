import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformResponse } from './transformResponse';

export async function fetchSurvey(id: string) {
  const request = createRequest(
    `${DEPOT_API_URL}/surveys/${id}.json?include=move_task_order,move_task_order.orders,service,move_task_order.orders,move_task_order.orders.customer,move_task_order.orders.origin,move_task_order.orders.destination&fields[order.customer]=first_name,last_name`
  );

  try {
    const response = await fetch(request);
    const json = await response.json();
    if (response.ok) {
      return transformResponse(json.data);
    } else {
      let message = 'Unknown Error';
      if (json.error) {
        message = json.error;
      }
      throw new Error(`Failed to fetch survey (${message})`);
    }
  } catch (error) {
    console.error(error);
  }
}
