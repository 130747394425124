import { OBJECT_METHOD } from '@/constants/objectMethods';
import { ObjectMethod } from '@/types/ObjectMethod';

export const filterDeletedItems = <T extends { _method?: ObjectMethod }>(
  items: Array<T>
) => {
  return items.filter(
    ({ _method }) =>
      typeof _method === 'undefined' || _method !== OBJECT_METHOD.DESTROY
  );
};
