import { useMediaQuery, useTheme } from '@mui/material';

export const useMediaMatches = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const xsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));

  return {
    xs,
    xsUp,
    sm,
    smDown,
    smUp,
    md,
    mdDown,
    mdUp,
    lg,
    lgDown,
    lgUp,
    xl,
    xlDown,
  };
};
