import { Typography, Menu, MenuItem } from '@mui/material';

interface ItemActionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onRemoveItem: () => void;
  onEditItem: () => void;
}

export function ItemActionMenu({
  anchorEl,
  open,
  onClose,
  onRemoveItem,
  onEditItem,
}: ItemActionMenuProps) {
  return (
    <>
      <Menu
        id="item-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-label': 'basic-button',
        }}
      >
        <MenuItem onClick={onEditItem}>
          <Typography>Edit Item</Typography>
        </MenuItem>
        <MenuItem onClick={onRemoveItem}>
          <Typography color="error">Remove Item</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
