import { Card, CardContent, Grid, Skeleton, Stack } from '@mui/material';

const SingleCard = () => (
  <Card sx={{ height: 1 }}>
    <CardContent sx={{ height: 1 }}>
      <Stack gap={2} width={1} height={1}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={56}
          sx={{ flexShrink: 0 }}
        />

        <Skeleton variant="rectangular" width="100%" height="100%" />

        <Skeleton variant="rectangular" width="100%" height="100%" />

        <Stack gap={1} style={{ height: 200, width: '100%' }}>
          <Skeleton variant="rectangular" width="50%" height={10} />
          <Skeleton variant="rectangular" width="50%" height={10} />
          <Skeleton variant="rectangular" width="50%" height={10} />
          <Skeleton
            variant="rectangular"
            width="30%"
            height={10}
            sx={{ mt: 2 }}
          />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export function SingleSkeletonScreen() {
  return (
    <Stack height={1} width={1}>
      <SingleCard />
    </Stack>
  );
}

export function DoubleSkeletonScreen() {
  return (
    <Grid container height={1} spacing={2}>
      <Grid item lg={4} height={1}>
        <SingleCard />
      </Grid>
      <Grid item lg={8} height={1}>
        <Card sx={{ height: 1 }}>
          <CardContent sx={{ height: 1 }}>
            <Stack gap={2} width={1} height={1}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={56}
                sx={{ flexShrink: 0 }}
              />

              <Skeleton variant="rectangular" width="100%" height={40} />

              <Skeleton variant="rectangular" width="100%" height={40} />

              <Stack direction="row" gap={2} height={1}>
                <Skeleton variant="rectangular" height="100%" width="100%" />

                <Skeleton variant="rectangular" height="100%" width="100%" />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
