import { Service, SurveyServiceCompletionStatus } from '@/types';
import { DEPOT_API_URL } from '../constants';
import { getFetchError } from '../utils/fetch';
import { createRequest } from '../createRequest';
import { SERVICE_COMPLETION_STATUS } from '@/constants/surveyService';

const handleError = (json: {
  errors: Array<{ code: string; detail: string }>;
}) => {
  const { errors } = json;
  throw new Error(
    getFetchError(errors) ||
      "Failed to update the survey's status due to an unknown error"
  );
};

export async function updateSurveyServiceStatus(
  id: string,
  status: keyof typeof SERVICE_COMPLETION_STATUS
) {
  const body = JSON.stringify({
    data: {
      id: id,
      type: 'services',
      attributes: {
        completion_status: status,
      },
    },
  });

  const request = createRequest(`${DEPOT_API_URL}/services/${id}.json`, {
    method: 'PATCH',
    body,
  });

  const response = await fetch(request);
  const json = await response.json();
  const { data } = json || {};

  return response.ok
    ? {
        service: {
          completionStatus: data.attributes
            .completion_status as SurveyServiceCompletionStatus,
          id: data.id,
        },
      }
    : handleError(json);
}
