import { NextRouter } from 'next/router';

export const softReplaceUrlParams = (
  router: NextRouter,
  removeParams: Array<string>
) => {
  (removeParams || []).forEach((key) => {
    delete router.query[key];
  });

  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    {
      shallow: true,
    }
  );
};
