import { ReactNode, useEffect, useState } from 'react';
import { fetchRefreshToken } from '@/api';
import { useInterval } from '@/hooks';
import { getCookies, parseJwt } from '@/utils';
import { LoadingDisplay } from '@/components/common/LoadingDisplay';
import { useOnCookieChanged } from '@/hooks/useOnCookieChanged';
import { useAuthNotification } from './useAuthNotification';
import { AuthContext } from './AuthContext';

const REFRESH_TIMEOUT = 1000 * 60 * 10; // 10 minutes

function msToExipry(expiry: number) {
  const now = Date.now();
  // expiry is in seconds
  return expiry * 1000 - now;
}

export function AuthGuard({ children }: { children: ReactNode }) {
  const showAuthNotification = useAuthNotification();
  const [loading, setLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true);

  const checkIfAuthorized = (jwt?: string | undefined) => {
    const signedIn = Boolean(jwt);
    setIsSignedIn(signedIn);
    showAuthNotification(signedIn);
  };

  useOnCookieChanged('jwt', checkIfAuthorized);

  // POLL: The only way currently to refresh the token is to POST
  // to the CRM /index.php file and keep the token alive.
  //
  // TODO: Do proper refresh once we have a refresh mechanism
  useInterval(async () => {
    const cookies = getCookies<{ jwt: string }>();
    if (cookies.jwt) {
      await fetchRefreshToken();
    }
  }, REFRESH_TIMEOUT);

  useEffect(() => {
    const cookies = getCookies<{ jwt: string }>();
    checkIfAuthorized(cookies.jwt);
    const jwtBody = parseJwt(cookies.jwt);
    if (jwtBody && msToExipry(jwtBody?.exp) < REFRESH_TIMEOUT) {
      // refresh the token upon loading the app to avoid timing issues
      fetchRefreshToken();
    }
    setLoading(false);
  }, []);

  // TODO: Need to figure out how to reinitialize the app
  if (loading) {
    return <LoadingDisplay />;
  } else {
    return (
      <AuthContext.Provider value={{ isSignedIn }}>
        {children}
      </AuthContext.Provider>
    );
  }
}
