import { MouseEvent } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITEM_CATEGORY } from '@/constants';
import { Item } from '@/types';
import { Typography, ListItem, Chip, IconButton } from '@mui/material';
import { Stack, Box } from '@mui/system';

interface RoomItemProps {
  item: Item;
  onClick: (item: Item, el: HTMLElement) => void;
}

const isMemberProGear = (item: Item) =>
  item.category === ITEM_CATEGORY.MEMBER_PRO_GEAR;

const isSpouseProGear = (item: Item) =>
  item.category === ITEM_CATEGORY.SPOUSE_PRO_GEAR;

const isProGear = (item: Item) =>
  isMemberProGear(item) || isSpouseProGear(item);

const RoomItemTextValue = ({ value }: { value: number }) => {
  const isDisabled = value < 1;
  // TODO: Integrate these into the theme if they are correct
  const colorDisabled = 'rgba(0, 0, 0, 0.38)';
  const colorPrimary = 'rgba(0, 0, 0, 0.87)';
  return (
    <Typography
      variant="subtitle2"
      noWrap={true}
      component="span"
      // TODO: Using style since color or sx wasn't working
      // Revisit when the theming is updated
      style={{
        color: isDisabled ? colorDisabled : colorPrimary,
      }}
    >
      {value}
    </Typography>
  );
};

export const RoomItem = ({ item, onClick }: RoomItemProps) => {
  const isMPG = isMemberProGear(item);
  const isPG = isProGear(item);

  const onItemClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(item, event.currentTarget);
  };

  return (
    <ListItem divider sx={{ py: 0 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '4fr 1fr 1fr 1fr 40px',
            sm: '5fr 1fr 1fr 1fr 40px',
          },
          alignItems: 'center',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="start"
          gap={1}
        >
          <Typography noWrap={true} sx={{ justifySelf: 'start' }}>
            {item.name}
          </Typography>

          {isPG ? (
            <Chip
              size="small"
              label={isMPG ? 'PG' : 'SG'}
              variant={isMPG ? 'teal' : 'deepPurple'}
            />
          ) : null}
        </Stack>
        <RoomItemTextValue value={item.cube} />
        <RoomItemTextValue value={item.going} />
        <RoomItemTextValue value={item.notGoing} />
        <div style={{ justifySelf: 'end' }}>
          <IconButton
            aria-label={`${item.name} Action Button`}
            onClick={onItemClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </Box>
    </ListItem>
  );
};
