import AddIcon from '@mui/icons-material/Add';
import MuiButton, { ButtonProps } from '@mui/material/Button';

export function Button({ children, ...props }: ButtonProps) {
  return (
    <MuiButton variant="contained" {...props}>
      {children}
    </MuiButton>
  );
}

export function OutlinedButton({ children, ...props }: ButtonProps) {
  return (
    <MuiButton variant="outlined" {...props}>
      {children}
    </MuiButton>
  );
}

export function TextButton({ children, ...props }: ButtonProps) {
  return <MuiButton {...props}>{children}</MuiButton>;
}

export function AddButton({ children, ...props }: ButtonProps) {
  return (
    <MuiButton variant="contained" startIcon={<AddIcon />} {...props}>
      {children}
    </MuiButton>
  );
}

export function OutlinedAddButton({ children, ...props }: ButtonProps) {
  return (
    <Button startIcon={<AddIcon />} variant="outlined" {...props}>
      {children}
    </Button>
  );
}
