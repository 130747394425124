import { ReactNode, useCallback, useState } from 'react';
import {
  defaultOptions,
  SnackbarContext,
  SnackbarOptions,
} from './SnackbarContext';

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [message, setMessage] = useState('');
  const [options, setOptions] = useState(defaultOptions);
  const open = Boolean(message);

  const showMessage = useCallback(
    (message: string, options?: SnackbarOptions) => {
      setMessage(message);
      setOptions({
        ...defaultOptions,
        ...options,
      });
    },
    []
  );

  const showError = useCallback(
    (message: string, options?: SnackbarOptions) => {
      showMessage(message, {
        ...options,
        severity: 'error',
      });
    },
    [showMessage]
  );

  const showSuccess = useCallback(
    (message: string, options?: SnackbarOptions) => {
      showMessage(message, {
        ...options,
        severity: 'success',
      });
    },
    [showMessage]
  );

  const showInfo = useCallback(
    (message: string, options?: SnackbarOptions) => {
      showMessage(message, {
        ...options,
        severity: 'info',
      });
    },
    [showMessage]
  );

  const showWarning = useCallback(
    (message: string, options?: SnackbarOptions) => {
      showMessage(message, {
        ...options,
        severity: 'warning',
      });
    },
    [showMessage]
  );

  const clearMessage = useCallback(() => {
    setMessage('');
    setOptions(defaultOptions);
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        message,
        options,
        open,
        showMessage,
        showError,
        showInfo,
        showSuccess,
        showWarning,
        clearMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
