import { useAtom } from 'jotai';
import {
  CardContent,
  Paper,
  ListItem,
  Stack,
  Typography,
  List,
} from '@mui/material';
import {
  addRoom,
  removeRoom,
  updateRoom,
  removeItemsInRoom,
  removeItemsInRoomSegment,
} from '@/utils';
import { filterDeletedItems } from '@/utils/filterDeletedItems';
import { Room, RoomListItem } from '@/types';
import { useMediaMatches, useSurvey, useSurveyReport } from '@/hooks';
import { segmentItemsAtom } from '@/store';
import {
  RoomList,
  AddRoomButton,
  SurveyTotals,
  SurveyTotalsFooter,
} from './components';
import { AppLayout } from '@/components/layouts';
import { AddSegmentControl } from '@/components/common/Segments';
import { selectedSegmentIdAtom } from '@/store';

export function SurveyPage() {
  const { rooms, setItems, setRooms } = useSurvey();
  const [selectedSegmentId] = useAtom(selectedSegmentIdAtom);
  const [allSegmentItems] = useAtom(segmentItemsAtom);
  const segmentItems = filterDeletedItems(allSegmentItems);
  const surveyReport = useSurveyReport();
  const matches = useMediaMatches();

  const onRemoveRoom = (room: Room) => {
    setItems((prevItems) => removeItemsInRoom(prevItems, room.id));
    setRooms((prevRooms) => removeRoom(prevRooms, room));
  };

  const onAddRoom = (room: RoomListItem) => {
    setRooms((prevRooms) => addRoom(prevRooms, room));
  };

  const onEditRoom = (room: Room) => {
    setRooms((prevRooms) => updateRoom(prevRooms, room));
  };

  const onRemoveRoomItems = (room: Room) => {
    setItems((prevItems) =>
      removeItemsInRoomSegment(prevItems, selectedSegmentId, room.id)
    );
  };
  return (
    <Paper sx={{ height: 1 }}>
      <Stack height={1}>
        <List>
          <ListItem>
            <Stack direction="row" justifyContent="space-between" width={1}>
              <Typography variant="h6">
                {surveyReport.segmentItemsTotal.going} Surveyed Items
              </Typography>

              <AddRoomButton onAddRoom={onAddRoom}>
                {matches.xs ? 'Room' : 'Add Room'}
              </AddRoomButton>
            </Stack>
          </ListItem>
        </List>

        {/*segment control */}
        <AddSegmentControl />

        <CardContent sx={{ marginTop: '0' }}>
          <SurveyTotals items={segmentItems} />
        </CardContent>

        <div style={{ flex: 1, height: 0 }}>
          <RoomList
            rooms={rooms}
            items={segmentItems}
            onAddRoom={onAddRoom}
            onRemoveRoomItems={onRemoveRoomItems}
            onRemoveRoom={onRemoveRoom}
            onEditRoom={onEditRoom}
          />
        </div>

        <CardContent>
          <SurveyTotalsFooter
            segmentTotals={surveyReport.segmentItemsTotal}
            orderTotals={surveyReport.orderItemsTotal}
            itemsTotals={surveyReport.itemsTotal}
            proGearTotals={surveyReport.proGearItemsTotal}
            weightFactor={surveyReport.weightFactor}
          />
        </CardContent>
      </Stack>
    </Paper>
  );
}

SurveyPage.getLayout = AppLayout;
