import { OBJECT_METHODS } from '@/constants';
import { Survey } from '@/types/Survey';
import { ObjectMethod } from '@/types/ObjectMethod';
import { convertItemsToSnakeCase } from '../utils/convertItemsToSnakeCase';
import { removeMethodProperty } from '../utils/removeMethodProperty';
import { mapIncluded } from '../utils/mapIncluded';
import { Item } from '@/types/Item';
import { Room } from '@/types/Room';
import { removeOrderNumberProperty } from '@/api/utils/removeProperties';
import { convertSegmentsToSnakeCase } from '@/api/utils/convertSegmentsToSnakeCase';

const mapRelationship =
  (type: string) =>
  ({ id, _method }: { id: string; _method?: ObjectMethod }) => ({
    id,
    type,
    method: _method,
  });

const filterMethodMissing = <T extends { _method?: ObjectMethod }>(
  list: Array<T>
) => {
  return list.filter(
    (listItem) =>
      typeof listItem._method !== 'undefined' &&
      OBJECT_METHODS.includes(listItem._method)
  );
};

const convertImagesMethod = <T extends Item | Room>(item: T) => ({
  ...item,
  images: item.images.map(({ _method, ...rest }) => ({
    ...rest,
    ...(_method ? { method: _method } : {}),
  })),
});

export function transformRequest(survey: Survey) {
  const { id, segments, rooms, items } = survey;

  const changedSegments = filterMethodMissing(segments);
  const changedRooms = filterMethodMissing(rooms);
  const changedItems = filterMethodMissing(items);

  return {
    data: {
      id,
      type: 'surveys',
      relationships: {
        segments: {
          data: changedSegments.map(mapRelationship('segments')),
        },
        rooms: {
          data: changedRooms.map(mapRelationship('survey_rooms')),
        },
        items: {
          data: changedItems.map(mapRelationship('survey_items')),
        },
      },
    },
    included: [
      ...changedSegments
        .map(removeMethodProperty)
        .map(removeOrderNumberProperty)
        .map(convertSegmentsToSnakeCase)
        .map(mapIncluded('segments')),
      ...changedRooms
        .map(convertImagesMethod)
        .map(removeMethodProperty)
        .map(mapIncluded('survey_rooms')),
      ...changedItems
        .map(convertImagesMethod)
        .map(removeMethodProperty)
        .map(convertItemsToSnakeCase)
        .map(mapIncluded('survey_items')),
    ],
  };
}
