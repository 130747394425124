import { createContext, ReactNode } from 'react';
import { ClickAwayListenerProps } from '@mui/material';

export interface SnackbarOptions {
  autoHideDuration?: number | null;
  severity?: 'success' | 'error' | 'warning' | 'info';
  action?: ReactNode;
  title?: string;
  ClickAwayListenerProps?: Partial<ClickAwayListenerProps>;
}

type SetMessage = (message: string, options?: SnackbarOptions) => void;

interface SnackbarContext {
  message: string;
  open: boolean;
  options: SnackbarOptions;
  showMessage: SetMessage;
  showError: SetMessage;
  showSuccess: SetMessage;
  showInfo: SetMessage;
  showWarning: SetMessage;
  clearMessage: () => void;
}

export const defaultOptions: SnackbarOptions = {
  autoHideDuration: null,
  severity: 'success',
  action: undefined,
  title: '',
};

const setMessageNoOp = (message: string, options?: SnackbarOptions) => {};
export const defaultContext = {
  message: '',
  open: false,
  options: defaultOptions,
  showMessage: setMessageNoOp,
  showError: setMessageNoOp,
  showSuccess: setMessageNoOp,
  showInfo: setMessageNoOp,
  showWarning: setMessageNoOp,
  clearMessage: () => {},
};

export const SnackbarContext = createContext<SnackbarContext>(defaultContext);
