interface TabPanelProps {
  children?: React.ReactNode;
  id: string;
  value: string;
  style?: React.CSSProperties;
}

export function TabPanel({
  children,
  value,
  id,
  style,
  ...other
}: TabPanelProps) {
  const isTabSelected = value === id;

  return (
    <div
      role="tabpanel"
      hidden={!isTabSelected}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      style={style}
      {...other}
    >
      {isTabSelected && children}
    </div>
  );
}
