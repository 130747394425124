import { focusAtom } from 'jotai-optics';
import { appAtom } from './appAtom';

const initialValue = {
  completed: false,
  items: [],
  moveTaskOrderId: '',
  orderId: '',
  rooms: [],
  segments: [],
};

export const surveyAtom = focusAtom(appAtom, (optic) => optic.prop('survey'));
