import { CRMButtonSignIn } from '@/components/common/CRMButton';
import { useSnackbar } from '@/components/common/Snackbar/useSnackbar';

const noop = () => {};

export function useAuthNotification() {
  const { showMessage } = useSnackbar();

  const showSignedOutMessage = () => {
    showMessage('Not Signed In', {
      severity: 'error',
      action: <CRMButtonSignIn>Sign Into CRM</CRMButtonSignIn>,
      autoHideDuration: null,
      ClickAwayListenerProps: {
        onClickAway: noop,
      },
    });
  };

  const showSignedInMessage = () => {
    showMessage('Signed In', { severity: 'success', autoHideDuration: 5000 });
  };

  const showAuthNotification = (isSignedIn: boolean) => {
    isSignedIn ? showSignedInMessage() : showSignedOutMessage();
  };

  return showAuthNotification;
}
