import { WEIGHT_FACTOR } from '@/constants';

type CalculateWeightFromCube = {
  (cube: number, weightFactor?: number): number;
};

export const calculateWeightFromCube: CalculateWeightFromCube = (
  cube,
  weightFactor = WEIGHT_FACTOR
) => {
  return cube * weightFactor;
};
