import { atomWithStorage } from 'jotai/utils';
import { promisifyWorker } from '@/utils';
import { hasFileStorage } from '@/constants';

export function atomWithWorkerStorage<T>(key: string, initial: T) {
  return atomWithStorage<T>(
    key,
    initial,
    createFileSystemWorkerStorage<T>(initial)
  );
}

function createFileSystemWorkerStorage<T>(initial: T) {
  if (typeof Worker === 'undefined') return;

  const _worker = hasFileStorage
    ? new Worker(new URL('@/workers/fileStorageWorker', import.meta.url))
    : new Worker(new URL('@/workers/encryptedStorageWorker', import.meta.url));
  const worker = promisifyWorker<T>(_worker);

  return {
    async setItem(key: string, value: T) {
      await worker
        .postMessage({
          event: 'setItem',
          value,
          key,
        })
        .catch((e) => console.warn('Error setting item', key, e));
    },
    async getItem(key: string) {
      const response = await worker
        .postMessage({
          event: 'getItem',
          key,
        })
        .catch((e) => console.warn('Error getting item', key, e));
      if (response !== undefined) {
        return response;
      }
      if (initial !== undefined) {
        await worker.postMessage({
          event: 'setItem',
          value: initial,
          key,
        });
      }
      return initial;
    },
    async removeItem(key: string) {
      await worker
        .postMessage({
          event: 'removeItem',
          key,
        })
        .catch((e) => console.warn('Error removing item', key, e));
    },
  };
}
