import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface ConfirmStartSurveyModalProps {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmStartSurveyModal({
  open,
  loading = true,
  onCancel,
  onConfirm,
}: ConfirmStartSurveyModalProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Start Survey</DialogTitle>

      <DialogContent>
        Are you sure you would like to start this survey?
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
          data-testid="ConfirmStartButton"
          startIcon={
            loading ? <CircularProgress size="12px" color="inherit" /> : null
          }
        >
          {loading ? 'Starting...' : 'Start Survey'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
