import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextButton } from '@/components';
import { Item } from '@/types';

interface ConfirmRemoveItemModalProps {
  open: boolean;
  item: Item | null;
  onCancel: () => void;
  onConfirm: (item: Item) => void;
}

export function ConfirmRemoveItemModal({
  open,
  item,
  onCancel,
  onConfirm,
}: ConfirmRemoveItemModalProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Remove Item</DialogTitle>

      <DialogContent>
        Are you sure you would like to remove this item? All data inside this
        item will be deleted.
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onCancel}>Cancel</TextButton>
        <TextButton color="error" onClick={() => onConfirm(item as Item)}>
          Remove Item
        </TextButton>
      </DialogActions>
    </Dialog>
  );
}
