import { useCallback, useState, MouseEvent } from 'react';

interface UsePopupStateProps<T> {
  popupAnchorEl: T | null;
  isPopupOpen: boolean;
  openPopup: (event: MouseEvent<T>) => void;
  closePopup: () => void;
}

// This hook is similar to the material-ui-popup-state
// (https://github.com/jcoreio/material-ui-popup-state) library, but with a
// very simple API.
//
// If we need more functionality we can switch to using that,
// but it would add 3.6k (Gzipped) to the bundle.
// (https://bundlephobia.com/package/material-ui-popup-state@5.0.4)
export function usePopupState<T = HTMLButtonElement>(): UsePopupStateProps<T> {
  const [popupAnchorEl, setPopupAnchorEl] = useState<T | null>(null);
  const isPopupOpen = Boolean(popupAnchorEl);

  const openPopup = useCallback((event: MouseEvent<T>) => {
    setPopupAnchorEl(event.currentTarget);
  }, []);

  const closePopup = useCallback(() => {
    setPopupAnchorEl(null);
  }, []);

  return {
    popupAnchorEl,
    isPopupOpen,
    openPopup,
    closePopup,
  };
}
