const defaultWindowSettings =
  'width=400,height=400,left=150,top=200,popup,resizable=yes';

export function useOpenVideoPopup(
  path: string,
  name: string,
  settings?: string
) {
  const openVideo = () => {
    const handle = window.open(path, name, settings ?? defaultWindowSettings);
    if (!handle) {
      // TODO: Show something in the UI
      console.warn('Video window was not allowed to open.');
    }
  };

  return openVideo;
}
