import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button } from '@/components/common';
import { Order, Segment } from '@/types';
import {
  SelectChangeEvent,
  Stack,
  Container,
  Typography,
  ListItem,
  InputLabel,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { SEGMENT_FROMS, SEGMENT_TOS } from '@/constants';
import { isSameSegment } from '@/utils';
import {
  DUPLICATE_SEGMENT_ERROR,
  SEGMENT_FROM_DEFAULT,
  SEGMENT_TO_DEFAULT,
} from './constants';

interface SegmentMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSubmit: (segment: Segment) => void;
  orders: Array<Order>;
  segments: Array<Segment>;
  segment?: Segment;
  segmentSelectLabel: string;
  submitButtonLabel: string;
}

export function SegmentMenu({
  anchorEl,
  open,
  onClose,
  onSubmit,
  orders,
  segments,
  segment,
  segmentSelectLabel,
  submitButtonLabel,
}: SegmentMenuProps) {
  const orderIdDefault = segment?.orderId ?? orders[0]?.id;
  const segmentFromDefault = segment?.from ?? SEGMENT_FROM_DEFAULT;
  const segmentToDefault = segment?.to ?? SEGMENT_TO_DEFAULT;

  const [from, setFrom] = useState<string>(segmentFromDefault);
  const [to, setTo] = useState<string>(segmentToDefault);
  const [orderId, setOrderId] = useState<string>(orderIdDefault);
  const [error, setError] = useState('');

  const resetDefaults = () => {
    setFrom(segmentFromDefault);
    setTo(segmentToDefault);
    setOrderId(orderIdDefault);
    setError('');
  };

  const handleClose = () => {
    resetDefaults();
    onClose();
  };

  const handleSubmit = () => {
    const newSegment = {
      id: segment?.id,
      to,
      from,
      orderId,
    } as Segment;

    const existing = segments.find((seg) => isSameSegment(seg, newSegment));
    if (existing) {
      setError(DUPLICATE_SEGMENT_ERROR);
    } else {
      onSubmit(newSegment);
      resetDefaults();
    }
  };

  const handleFromChange = (event: SelectChangeEvent<unknown>) => {
    setFrom(event.target.value as string);
    setError('');
  };

  const handleOrderChange = (event: SelectChangeEvent<unknown>) => {
    setOrderId(event.target.value as string);
    setError('');
  };

  const handleToChange = (event: SelectChangeEvent<unknown>) => {
    setTo(event.target.value as string);
    setError('');
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Container sx={{ py: 2, width: 275 }}>
        <ListItem>
          <FormControl size="small" fullWidth>
            <InputLabel id="segment-from-orderId">Order Number</InputLabel>
            <Select
              labelId="segment-orderId"
              id="segment-orderId"
              value={orderId}
              label={segmentSelectLabel}
              onChange={handleOrderChange}
            >
              {orders?.map((order) => (
                <MenuItem key={order?.id} value={order?.id}>
                  <Typography>
                    {order?.shipmentType} {order?.number}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl size="small" fullWidth>
            <InputLabel id="segment-from-type">From Type</InputLabel>
            <Select
              labelId="from-type"
              id="from-type"
              value={from}
              label="From Type"
              onChange={handleFromChange}
            >
              {SEGMENT_FROMS.map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl size="small" fullWidth>
            <InputLabel id="segment-from-type">To Type</InputLabel>
            <Select
              labelId="to-type"
              id="to-type"
              value={to}
              label="To Type"
              onChange={handleToChange}
            >
              {SEGMENT_TOS.map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>

        <Box>
          <Typography color="error" textAlign="center">
            {error ?? ''}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          padding={1}
        >
          <Button onClick={handleSubmit}>{submitButtonLabel}</Button>
        </Stack>
      </Container>
    </Popover>
  );
}
