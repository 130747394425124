import { getCookies } from '@/utils';

interface AppCookies {
  jwt: string | undefined;
}

export function createRequest(
  url: RequestInfo | URL,
  options: RequestInit | undefined = {}
) {
  const cookies = getCookies<AppCookies>();
  const headers = new Headers({
    Cache: 'no-cache',
    'Content-Type': 'application/json',
  });

  if (cookies && 'jwt' in cookies) {
    headers.append('Authorization', `Bearer ${cookies.jwt}`);
  }

  Object.entries(options.headers || {}).forEach(([key, value]) => {
    headers.append(key, value);
  });

  return new Request(url, { ...options, headers });
}
