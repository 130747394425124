interface ObjectWithName {
  name: string;
  [key: string]: unknown;
}

const downCase = (str: string) => (str || '').toLowerCase();

export const filterByStartsWith =
  (searchString: string) =>
  ({ name }: ObjectWithName) =>
    !searchString || downCase(name).startsWith(downCase(searchString));

export const filterByIncludes =
  (searchString: string) =>
  ({ name }: ObjectWithName) =>
    !searchString || downCase(name).includes(downCase(searchString));
