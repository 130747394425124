import { FormEvent, useState } from 'react';
import {
  Modal,
  Box,
  Paper,
  CardContent,
  ListItem,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Room } from '@/types';
import { ImageGallery } from './ImageGallery';
import { Button } from './Button';
import { ImageDropzone } from './ImageDropzone';
import { Image } from '@/types/Image';
import { maybeAddImagesFromStrings, removeImages } from '@/utils';

interface EditRoomModalProps {
  open: boolean;
  room: Room | null;
  onClose: () => void;
  onChange: (room: Room) => void;
}

export function EditRoomModal({
  open,
  room,
  onClose,
  onChange,
}: EditRoomModalProps) {
  const [images, setImages] = useState(room?.images || []);

  const handleImageUpload = (imageStrings: Array<string>) => {
    setImages((prevImages) =>
      maybeAddImagesFromStrings(prevImages, imageStrings)
    );
  };

  const handleImageDelete = (image: Image) => {
    setImages((prevImages) => removeImages(prevImages, image));
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    onChange({
      ...room,
      name: target.roomName.value,
      images,
    } as Room);
  };

  return (
    <Modal open={open} onClose={onClose} aria-label="Edit Item Modal">
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { lg: '40vw', xs: '85vw' },
          overflowY: 'auto',
        }}
      >
        <Paper sx={{ height: 1 }}>
          <CardContent>
            <form onSubmit={handleFormSubmit}>
              <ListItem divider sx={{ mb: 4 }}>
                <Stack
                  direction="row"
                  width={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">{room?.name} Details</Typography>

                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </ListItem>

              <ListItem divider sx={{ mb: 4 }}>
                <Stack
                  direction="row"
                  width={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle2">Room Alias</Typography>

                  <TextField
                    required
                    id="edit-room-alias-input"
                    name="roomName"
                    size="small"
                    label="Room Alias"
                    placeholder="Bedroom"
                    InputLabelProps={{ shrink: true, required: false }}
                    defaultValue={room?.name}
                  />
                </Stack>
              </ListItem>

              <ListItem>
                <Typography variant="subtitle2">Photos</Typography>
              </ListItem>
              <ListItem>
                <ImageGallery images={images} onDelete={handleImageDelete} />
              </ListItem>
              <ListItem>
                <ImageDropzone onUpload={handleImageUpload} />
              </ListItem>

              <Button type="submit" fullWidth sx={{ mt: 2 }}>
                Save
              </Button>
            </form>
          </CardContent>
        </Paper>
      </Box>
    </Modal>
  );
}
