import { useCallback, useMemo } from 'react';
import { createAsyncWorkerStorage } from '@/services/AsyncWorkerStorage';

export function useGetFromAsyncStorage<T>(key: string) {
  const AsyncFileStorage = useMemo(() => createAsyncWorkerStorage<T>(), []);

  const getFromAsyncStorage = useCallback(async () => {
    try {
      return await AsyncFileStorage?.getItem(key);
    } catch (e) {
      console.warn(
        `[useGetFromAsyncStorage] Could not retrieve "${key}" from storage:`,
        e
      );
      return null;
    }
  }, [AsyncFileStorage, key]);

  return getFromAsyncStorage;
}
