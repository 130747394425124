import { NextApiRequest } from 'next';

// Parses key1=value1&key2=value2... into dictionary { key: value }
const parseBodyString = (bodyString: string) => {
  const urlParams = new URLSearchParams(bodyString);
  return Object.fromEntries(urlParams);
};

export const parseBody = (request: NextApiRequest) => {
  return new Promise((resolve) => {
    let body: Array<Buffer> = [];
    request
      .on('data', (chunk) => body.push(chunk))
      .on('end', () => {
        // key1=value1&key2=value2...
        const bodyString = Buffer.concat(body).toString();
        resolve(parseBodyString(bodyString));
      });
  });
};
