import { SHIPMENT_TYPES, ShipmentType } from '@/types/ShipmentType';

export const formatShipmentType = (shipmentType: ShipmentType) => {
  switch (shipmentType) {
    case SHIPMENT_TYPES.INTERNATIONAL_HHG:
      return shipmentType.replace('INTERNATIONAL_', '');
    case SHIPMENT_TYPES.INTERNATIONAL_UB:
      return shipmentType.replace('INTERNATIONAL_', '');
    default:
      return shipmentType;
  }
};
