import { OBJECT_METHOD, OBJECT_METHODS } from '@/constants/objectMethods';
import { Survey } from '@/types/Survey';
import { convertItemsToSnakeCase } from '../utils/convertItemsToSnakeCase';
import { mapIncluded } from '../utils/mapIncluded';
import { ObjectMethod } from '@/types/ObjectMethod';
import { Image } from '@/types/Image';
import { removeMethodProperty } from '../utils/removeMethodProperty';
import { filterDeletedItems } from '@/utils/filterDeletedItems';
import { SURVEY_TYPE } from '@/constants/survey';
import { convertSegmentsToSnakeCase } from '@/api/utils/convertSegmentsToSnakeCase';
import { removeOrderNumberProperty } from '@/api/utils/removeProperties';

const mapRelationship =
  (type: string) =>
  ({ id }: { id: string }) => ({
    id,
    type,
    method: OBJECT_METHOD.CREATE,
  });

const filterNonCreateEntities = <T extends { _method?: ObjectMethod }>(
  list: Array<T>
) => {
  return list.filter(
    (listItem) =>
      typeof listItem._method === 'undefined' ||
      (OBJECT_METHODS.includes(listItem._method) &&
        listItem._method !== OBJECT_METHOD.DESTROY)
  );
};

const filterDeletedImages = <T extends { images: Array<Image> }>({
  images,
}: T) => {
  return filterDeletedItems(images);
};

export function transformRequest(survey: Survey) {
  const { segments, rooms, items, serviceId, surveyType } = survey;

  const changedSegments = filterNonCreateEntities(segments);
  const changedRooms = filterNonCreateEntities(rooms);
  const changedItems = filterNonCreateEntities(items);

  return {
    data: {
      type: 'surveys',
      attributes: {
        is_complete: true,
        survey_type: surveyType || SURVEY_TYPE.VIRTUAL,
      },
      relationships: {
        service: {
          data: {
            id: serviceId,
            type: 'services',
          },
        },
        segments: {
          data: changedSegments.map(mapRelationship('segments')),
        },
        rooms: {
          data: changedRooms.map(mapRelationship('survey_rooms')),
        },
        items: {
          data: changedItems.map(mapRelationship('survey_items')),
        },
      },
    },
    included: [
      ...changedSegments
        .map(removeMethodProperty)
        .map(removeOrderNumberProperty)
        .map(convertSegmentsToSnakeCase)
        .map(mapIncluded('segments')),
      ...changedRooms
        .filter(filterDeletedImages)
        .map(removeMethodProperty)
        .map(mapIncluded('survey_rooms')),
      ...changedItems
        .filter(filterDeletedImages)
        .map(removeMethodProperty)
        .map(convertItemsToSnakeCase)
        .map(mapIncluded('survey_items')),
    ],
  };
}
