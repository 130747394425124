import { SERVICE_COMPLETION_STATUS } from '@/constants/surveyService';

export function transformRequest(serviceId: string) {
  return {
    data: {
      id: serviceId,
      type: 'services',
      attributes: {
        completion_status: SERVICE_COMPLETION_STATUS.IN_PROGRESS,
      },
    },
  };
}
