import { useCallback, useEffect, useRef } from 'react';

export function useInterval(callback: Function, timeout: number) {
  const handler = useRef<number | null>(null);
  const savedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    if (handler.current === null) {
      handler.current = window.setInterval(savedCallback, timeout);
    }
    return () => {
      if (handler.current) {
        window.clearInterval(handler.current);
        handler.current = null;
      }
    };
  }, [timeout, savedCallback]);
}
