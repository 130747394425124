export type Decoder<T> = (value: string, name?: string) => T;

const decodeName: Decoder<string> = decodeURIComponent;

const decodeValue: Decoder<string> = (value = '') => {
  if (value[0] === '"') {
    value = value.slice(1, -1);
  }
  return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
};

export const getCookies = <T>() => {
  if (typeof document === 'undefined') {
    return {} as T;
  }

  return (document.cookie?.split(';') || []).reduce((cookies, row) => {
    const [name, value] = row.trim().split('=');
    return {
      ...cookies,
      [decodeName(name)]: decodeValue(value),
    };
  }, {}) as T;
};
