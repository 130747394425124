import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { ITEM_CATEGORY } from '@/constants';
import { ItemCategory } from '@/types';
import { useMediaMatches } from '@/hooks';

interface SelectorProps {
  value: ItemCategory | null;
  onChange: (category: ItemCategory) => void;
}

export function ProGearSelector({ value, onChange }: SelectorProps) {
  const matches = useMediaMatches();

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    category: ItemCategory
  ) => {
    onChange(category || ITEM_CATEGORY.HOUSEHOLD_GOODS);
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Pro Gear Selection"
    >
      {/* @ts-ignore: Due to variant not being supported yet */}
      <ToggleButton
        value={ITEM_CATEGORY.MEMBER_PRO_GEAR}
        aria-label="Member Pro Gear"
        variant="teal"
      >
        <Typography sx={{ whiteSpace: 'nowrap' }}>
          {matches.xlDown ? 'PG' : 'Pro Gear'}
        </Typography>
      </ToggleButton>

      {/* @ts-ignore: Due to variant not being supported yet */}
      <ToggleButton
        value={ITEM_CATEGORY.SPOUSE_PRO_GEAR}
        aria-label="Spouse Pro Gear"
        variant="deepPurple"
      >
        <Typography sx={{ whiteSpace: 'nowrap' }}>
          {matches.xlDown ? 'S. PG' : 'S. Pro Gear'}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
