import { Survey } from '@/types';
import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformRequest } from './transformRequest';
import { transformResponse } from './transformResponse';
import { getFetchError } from '../utils/fetch';

export async function startSurveyService(serviceId: string) {
  const transformed = transformRequest(serviceId);
  const body = JSON.stringify(transformed);

  const request = createRequest(`${DEPOT_API_URL}/services/${serviceId}.json`, {
    method: 'PATCH',
    body,
  });

  const response = await fetch(request);
  const json = await response.json();

  if (response.ok) {
    return transformResponse(json.data);
  } else {
    throw new Error(
      getFetchError(json.errors) ||
        'Failed to start survey due to an unknown error'
    );
  }
}
