import { useState } from 'react';
import { Order, Segment } from '@/types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SegmentOrderDisplay } from './SegmentOrderDisplay';
import { MenuItem } from '@mui/material';
import styled from '@emotion/styled';

interface SegmentSelectProps {
  labelId?: string;
  segments: Array<Segment>;
  orders: Array<Order>;
  selectedSegmentId: string;
  onChange: (segmentId: string) => void;
}

const SegmentOption = styled.button`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 4px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
`;

export function SegmentSelect({
  labelId = 'segment-select',
  segments,
  orders,
  selectedSegmentId,
  onChange,
}: SegmentSelectProps) {
  const [selectedSegment, setSelectedSegment] =
    useState<string>(selectedSegmentId);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newSegmentId = event.target.value as string;
    setSelectedSegment(newSegmentId);
    onChange(newSegmentId);
  };

  return (
    <Select
      id="segment-name-input"
      value={selectedSegment}
      onChange={handleChange}
      inputProps={{
        id: labelId,
        MenuProps: {
          sx: {
            '&& .Mui-selected': {
              '& button': {
                bgcolor: '#f2f4f6',
              },
              '&:hover': {
                bgcolor: '#f2f4f6',
              },
            },
          },
          PaperProps: {
            sx: {
              '& button:hover': {
                bgcolor: '#f2f4f6',
              },
            },
          },
        },
      }}
    >
      {segments.map((segment) => {
        const segmentsPotentialOrders = orders?.find(
          (order) => order?.id === segment?.orderId
        );

        return (
          <MenuItem key={`${segment.id}-${segment.orderId}`} value={segment.id}>
            <SegmentOption>
              <SegmentOrderDisplay
                segment={segment}
                order={segmentsPotentialOrders}
                className="dark"
              />
            </SegmentOption>
          </MenuItem>
        );
      })}
    </Select>
  );
}
