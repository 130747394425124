import { ROOM_TO_ITEMS_MAP } from '@/constants';
import { ItemDefinition, Room } from '@/types';

const createIsRoomItem = (room: Room) => (itemDefinition: ItemDefinition) => {
  const itemIdsInRoom = ROOM_TO_ITEMS_MAP[room?.classification] || [];
  return (
    itemIdsInRoom.includes(itemDefinition.id) &&
    !isCpItem(itemDefinition) &&
    !isPboItem(itemDefinition)
  );
};
const isCpItem = ({ isCp }: { isCp: boolean }) => isCp;
const isPboItem = ({ isPbo }: { isPbo: boolean }) => isPbo;

export const segmentItemDefinitions = (
  room: Room,
  itemDefinitions: Array<ItemDefinition>
) => {
  const isRoomItem = createIsRoomItem(room);
  return {
    room: itemDefinitions.filter(isRoomItem),
    all: itemDefinitions,
    cp: itemDefinitions.filter(isCpItem),
    pbo: itemDefinitions.filter(isPboItem),
  };
};
