import { Atom, atom } from 'jotai';
import { Room } from '@/types';
import { roomsAtom } from './roomsAtom';
import { findRoom } from '@/utils';

export const routeRoomIdAtom = atom<string | null>(null);

export const routeRoomAtom = atom((get) => {
  const rooms = get(roomsAtom as Atom<Array<Room>>);
  const roomId = get(routeRoomIdAtom);
  const room = findRoom(rooms, roomId as string);
  return room;
});
