import { ALPHABET } from '@/constants';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface LetterFilterProps {
  value: string;
  onClick: (letter: string) => void;
}

export function LetterFilter({ value, onClick }: LetterFilterProps) {
  // Tabs only accepts <boolean> to clear, not empty string
  const tabValue = value?.length ? value : false;

  return (
    <Tabs
      value={tabValue}
      aria-label="Filter by Letter"
      variant="scrollable"
      visibleScrollbar={false}
      scrollButtons={false}
      style={{
        background: '#dde2ea',
        color: '#0c4c6f',
      }}
    >
      {ALPHABET.map((letter) => (
        <Tab
          key={`letter-filter-${letter}`}
          value={letter}
          label={letter}
          sx={{ minWidth: 48 }}
          onClick={() => onClick(letter)}
        />
      ))}
    </Tabs>
  );
}
