import { Order } from '@/types';
import { Segment } from '@/types/Segment';
import { Avatar } from '@mui/material';
import { SegmentOrderNumber } from './SegmentOrderNumber';
import { theme } from '@/services';
import { isShipmentInternational } from '@/utils/shipments/isShipmentInternational';
import { formatShipmentType } from '@/utils/shipments/formatShipmentType';

interface SegmentOrderDisplay {
  segment: Segment;
  order?: Order;
  className?: 'dark' | 'normal';
}

const INTERNATIONAL_AVATAR_VARI = 'square';
const INTERNATIONAL_TEAL = '#00C7BE';
const PRIMARY_AVATAR_VARI = 'circular';
const PRIMARY_COLOR = theme.palette.primary.main;

export function SegmentOrderDisplay({
  segment,
  order,
  className,
}: SegmentOrderDisplay) {
  const orderShipmentType = order?.shipmentType
    ? formatShipmentType(order.shipmentType)
    : '';
  const international = isShipmentInternational(order?.shipmentType);

  return (
    <>
      {segment?.from}
      <Avatar // parallel to tripbuilder/src/ComponentLibrary/components/OrderTabs/OrderTabs.tsx, 53
        variant={
          international ? INTERNATIONAL_AVATAR_VARI : PRIMARY_AVATAR_VARI
        }
        sx={{
          width: 28,
          height: 28,
          lineHeight: 14,
          fontSize: 10,
          color: international ? '#000' : '#FAFAFB',
          fontWeight: international ? '700' : '',
          bgcolor: international ? INTERNATIONAL_TEAL : PRIMARY_COLOR,
          filter: 'opacity(75%)',
          '.Mui-selected &': {
            filter: ' opacity(100%)',
          },
        }}
      >
        {orderShipmentType}
      </Avatar>
      {segment?.to}
      <br />
      <SegmentOrderNumber segment={segment} className={className} />
    </>
  );
}
