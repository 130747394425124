import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformResponse } from './transformResponse';

export async function fetchVideoCredentials(id: string) {
  const request = createRequest(`${DEPOT_API_URL}/virtual_survey_credentials`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'virtual_survey_credentials',
        attributes: {
          service_id: id,
        },
      },
    }),
  });

  try {
    const response = await fetch(request);
    const { data, errors } = await response.json();

    if (response.ok) {
      return transformResponse(data);
    } else {
      throw new Error(errors.length ? errors[0].status : 'unknown status');
    }
  } catch (error) {
    console.error('Failed to fetch video credentials:', error);
  }
}
