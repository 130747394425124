import { useState } from 'react';
import { useRouter } from 'next/router';
import { useSetAtom } from 'jotai';
import { Grid, Stack } from '@mui/material';
import { useMediaMatches } from '@/hooks';
import { routeRoomIdAtom } from '@/store';
import {
  RoomItemsCard,
  ItemDefinitionsCard,
  SingleSkeletonScreen,
  DoubleSkeletonScreen,
} from './components';
import { AppLayout } from '@/components/layouts';
import { AddSegmentControl } from '@/components/common/Segments';

export function RoomItemsPage() {
  const setRouteRoomId = useSetAtom(routeRoomIdAtom);
  const router = useRouter();

  // TODO: Handle when there is an ID, but the room doesn't exist
  if (router.isReady) {
    setRouteRoomId(router.query.id as string);
  }

  const matches = useMediaMatches();
  const isSingleColumnLayout = matches.lgDown;

  const [showRoomItems, setShowRoomItems] = useState(true);
  const onClickShowAllItems = () => setShowRoomItems(false);
  const onClickShowRoomItems = () => setShowRoomItems(true);

  const DoubleColumnLayout = () => {
    return (
      <Grid container height={1} spacing={2}>
        <Grid item lg={12}>
          <AddSegmentControl />
        </Grid>
        <Grid item lg={4} height={1}>
          <RoomItemsCard onAddItems={onClickShowAllItems} />
        </Grid>
        <Grid item lg={8} height={1}>
          <ItemDefinitionsCard onShowItems={onClickShowRoomItems} />
        </Grid>
      </Grid>
    );
  };

  const SingleColumnLayout = () => {
    return (
      <Stack height={1}>
        <AddSegmentControl />
        {/* TODO: Add a transition to this */}
        {showRoomItems ? (
          <RoomItemsCard onAddItems={onClickShowAllItems} />
        ) : (
          <ItemDefinitionsCard onShowItems={onClickShowRoomItems} />
        )}
      </Stack>
    );
  };

  if (router.isReady) {
    return isSingleColumnLayout ? (
      <SingleColumnLayout />
    ) : (
      <DoubleColumnLayout />
    );
  } else {
    return isSingleColumnLayout ? (
      <SingleSkeletonScreen />
    ) : (
      <DoubleSkeletonScreen />
    );
  }
}

RoomItemsPage.getLayout = AppLayout;
