import { Atom, atom } from 'jotai';
import { itemsAtom } from './itemsAtom';
import { selectedSegmentIdAtom } from './selectedSegmentIdAtom';

export const segmentItemsAtom = atom((get) => {
  const items = get(itemsAtom);
  const selectedSegmentId = get(selectedSegmentIdAtom);
  return (items || []).filter(
    ({ segmentId }) => segmentId === selectedSegmentId
  );
});
