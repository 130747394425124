import { getCookies } from '@/utils';
import { useCallback, useEffect, useRef } from 'react';

// CookieStore is still experimental. https://caniuse.com/?search=CookieStore
// Add typings since they won't: https://github.com/microsoft/TypeScript/issues/47314
enum CookieSameSite {
  strict = 'strict',
  lax = 'lax',
  none = 'none',
}

export interface Cookie {
  name?: string;
  value?: string;
  domain: string | null;
  path?: string;
  expires: Date | number | null;
  secure?: boolean;
  sameSite?: CookieSameSite;
}

interface CookieChangeEvent extends EventInit {
  changed: Array<Cookie>;
  deleted: Array<Cookie>;
}

export function useOnCookieChanged(
  key: string,
  onChange: (cookieValue: string | undefined) => void
) {
  const handleChange = useCallback(onChange, [onChange]);
  const cookie = useRef<string>();

  useEffect(() => {
    let intervalId: number | undefined;

    const maybeOnChange = (key: string, changes: Array<Cookie>) => {
      const changed = changes.find(({ name }) => name === key);
      changed && handleChange(changed.value!);
    };

    const onCookieChange = ({ changed, deleted }: CookieChangeEvent) => {
      maybeOnChange(key, changed);
      maybeOnChange(key, deleted);
    };

    const pollCookies = (key: string) => {
      const cookies = getCookies<{ [key: string]: string }>();
      if (cookie.current !== cookies[key]) {
        cookie.current = cookies[key];
        handleChange(cookie.current);
      }
    };

    if ('cookieStore' in window) {
      // @ts-ignore: cookieStore is experimental and only implemented in Chromium
      window.cookieStore.addEventListener('change', onCookieChange);
      return () => {
        // @ts-ignore: cookieStore is experimental and only implemented in Chromium
        window.cookieStore.removeEventListener('change', onCookieChange);
      };
    } else {
      // Fallback for Safari and Firefox
      intervalId = window.setInterval(() => pollCookies(key), 1000);
      return () => intervalId && clearInterval(intervalId);
    }
  }, [key, handleChange]);
}
