interface VideoCredentialsResponse {
  attributes: {
    api_key: string;
    token: string;
    session_id: string;
  };
}

export const transformResponse = ({
  attributes,
}: VideoCredentialsResponse) => ({
  apiKey: attributes.api_key,
  token: attributes.token,
  sessionId: attributes.session_id,
});
