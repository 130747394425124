import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextButton } from '@/components';
import { Room } from '@/types';
import Stack from '@mui/material/Stack';
import { getTotals } from '@/utils';
import { useSurvey } from '@/hooks/useSurvey';

interface ConfirmRemoveRoomModalProps {
  open: boolean;
  room: Room;
  onCancel: () => void;
  onConfirm: (room: Room) => void;
}

export default function ConfirmRemoveRoomModal({
  open,
  room,
  onCancel,
  onConfirm,
}: ConfirmRemoveRoomModalProps) {
  const { items } = useSurvey();
  const roomItems = items.filter((item) => item.roomId === room.id);
  const { going } = getTotals(roomItems);

  return (
    <Dialog open={open}>
      <DialogTitle>Remove Room</DialogTitle>

      <DialogContent>
        Are you sure you would like to remove this room?
        {going > 0 ? (
          <Stack direction="row" justifyContent="center" marginTop={2}>
            <strong>
              {going} {going === 1 ? 'item' : 'items'} across ALL segments will
              be removed.
            </strong>
          </Stack>
        ) : null}
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onCancel}>Cancel</TextButton>
        <TextButton color="error" onClick={() => onConfirm(room)}>
          Remove Room
        </TextButton>
      </DialogActions>
    </Dialog>
  );
}
