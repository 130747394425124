import { ReactNode } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline } from '@mui/material';
import { theme } from './theme';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MUIThemeProvider theme={theme}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </MUIThemeProvider>
  );
};
