import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface ConfirmCompleteSurveyModalProps {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmCompleteSurveyModal({
  open,
  loading = true,
  onCancel,
  onConfirm,
}: ConfirmCompleteSurveyModalProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Complete Survey</DialogTitle>

      <DialogContent>
        Are you sure you would like to complete this survey?
        <b>
          {' '}
          Upon completion, the survey video functionality will no longer be
          available to the Service Provider and the customer.
        </b>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
          data-testid="ConfirmCompleteButton"
          startIcon={
            loading ? <CircularProgress size="12px" color="inherit" /> : null
          }
        >
          {loading ? 'Completing...' : 'Complete Survey'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
