import { SURVEY_TYPE } from '@/constants/survey';
import { useSurvey } from './useSurvey';

export const useSurveyType = () => {
  const { survey } = useSurvey();

  const isVirtualSurvey = survey.surveyType === SURVEY_TYPE.VIRTUAL;
  const isOnsiteSurvey = !isVirtualSurvey;

  return {
    isVirtualSurvey,
    isOnsiteSurvey,
  };
};
