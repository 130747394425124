import { CardContent, Stack } from '@mui/material';
import {
  getSegment,
  removeSegment,
  updateSegment,
  removeItemsInSegment,
  createSegment,
} from '@/utils';
import { OrderTabLegend, SegmentsList } from './SegmentsList';
import { AddSegmentButton } from './AddSegmentButton';
import { Segment } from '@/types';
import { OBJECT_METHOD } from '@/constants/objectMethods';
import { selectedSegmentIdAtom } from '@/store';
import {
  useMediaMatches,
  useMoveTaskOrderOrders,
  useSurvey,
  useSurveyReport,
} from '@/hooks';
import { useAtom } from 'jotai';

export function AddSegmentControl() {
  const { segments, setSegments, setItems } = useSurvey();
  const orders = useMoveTaskOrderOrders();

  segments.forEach((segment) => {
    if (!segment.orderNumber) {
      segment.orderNumber =
        orders.find((order) => order.id === segment.orderId)?.number || '';
    }
  });

  const [selectedSegmentId, setSelectedSegmentId] = useAtom(
    selectedSegmentIdAtom
  );

  const handleAddSegment = (segment: Segment) => {
    const existing = getSegment(segments, segment);
    if (existing) {
      setSelectedSegmentId(existing.id);
    } else {
      const segmentOrder = orders.find((order) => order.id === segment.orderId);
      if (segmentOrder) {
        const newSegment = createSegment(segment, segmentOrder);
        setSegments((prevSegments) => [...prevSegments, newSegment]);
        setSelectedSegmentId(newSegment.id);
      }
    }
  };

  const handleSegmentClicked = (segment: Segment) => {
    setSelectedSegmentId(segment.id);
  };

  const onEditSegment = (segment: Segment) => {
    setSegments((prevSegments) => updateSegment(prevSegments, segment));
  };

  const onRemoveSegment = (segment: Segment) => {
    setItems((prevItems) => removeItemsInSegment(prevItems, segment.id));

    const updatedSegments = removeSegment(segments, segment);
    setSegments(updatedSegments);

    const displayedSegments = updatedSegments.filter(
      (segment) => segment._method !== OBJECT_METHOD.DESTROY
    );
    setSelectedSegmentId(displayedSegments[0]?.id);
  };

  const surveyReport = useSurveyReport();

  const matches = useMediaMatches();

  return (
    <CardContent sx={{ paddingTop: 0 }}>
      <OrderTabLegend orders={orders} />
      <Stack direction="row" gap={2} flexWrap="wrap">
        <SegmentsList
          segments={segments}
          orders={orders}
          itemsTotal={surveyReport.segmentItemsTotal.going}
          currentSegmentId={selectedSegmentId}
          onSegmentClicked={handleSegmentClicked}
          onEditSegment={onEditSegment}
          onRemoveSegment={onRemoveSegment}
        />

        <AddSegmentButton
          orders={orders}
          segments={segments}
          onAddSegment={handleAddSegment}
        >
          {matches.xs ? 'Segment' : 'New Segment'}
        </AddSegmentButton>
      </Stack>
    </CardContent>
  );
}
