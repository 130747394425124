const uniqueProperties = [
  'name',
  'weight',
  'cube',
  'length',
  'width',
  'height',
  'roomId',
  'segmentId',
  'category',
];

interface UniqueItemProps {
  name: string;
  weight: number | null;
  cube: number;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  roomId: string;
  segmentId: string;
  category: string;
}

export const areItemsTheSame = (
  item1: UniqueItemProps,
  item2: UniqueItemProps
) => {
  return uniqueProperties.every((property) => {
    return (
      item1[property as keyof UniqueItemProps] ===
      item2[property as keyof UniqueItemProps]
    );
  });
};
