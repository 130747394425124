import { Totals } from '@/types';
import { atom } from 'jotai';
import { getTotals } from '@/utils';
import { itemsAtom } from './itemsAtom';
import { segmentItemsAtom } from './segmentItemsAtom';
import { proGearItemsAtom } from './proGearItemsAtom';
import { filterDeletedItems } from '@/utils/filterDeletedItems';
import { orderItemsAtom } from '@/store/orderItemsAtom';

export const itemsTotalsAtom = atom<Totals>((get) => {
  const items = filterDeletedItems(get(itemsAtom) || []);
  const { going, notGoing, weight, cube } = getTotals(items);

  return {
    going,
    notGoing,
    weight,
    cube,
  };
});

export const segmentItemsTotalsAtom = atom<Totals>((get) => {
  const items = filterDeletedItems(get(segmentItemsAtom) || []);
  const { going, notGoing, weight, cube } = getTotals(items);

  return {
    going,
    notGoing,
    weight,
    cube,
  };
});

export const orderItemsTotalsAtom = atom<Totals>((get) => {
  const items = filterDeletedItems(get(orderItemsAtom) || []);
  const { going, notGoing, weight, cube } = getTotals(items);

  return {
    going,
    notGoing,
    weight,
    cube,
  };
});

export const proGearItemsTotalsAtom = atom<Totals>((get) => {
  const items = filterDeletedItems(get(proGearItemsAtom) || []);
  const { going, notGoing, weight, cube } = getTotals(items);

  return {
    going,
    notGoing,
    weight,
    cube,
  };
});
