import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';
import { useSnackbar } from './useSnackbar';

export function Snackbar() {
  const { open, message, clearMessage, options } = useSnackbar();

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={options.autoHideDuration}
      onClose={clearMessage}
      ClickAwayListenerProps={options.ClickAwayListenerProps}
    >
      <Alert
        onClose={clearMessage}
        severity={options.severity}
        sx={{ width: 1 }}
        action={options.action ? options.action : null}
      >
        {options.title ? <AlertTitle>{options.title}</AlertTitle> : null}
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
