import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ROOM_LIST } from '@/constants';
import { RoomListItem } from '@/types';
import Paper from '@mui/material/Paper';

interface AddRoomMenuProps {
  anchorEl?: Element | null | undefined;
  open: boolean;
  onClose: () => void;
  onSelectRoom: (room: RoomListItem) => void;
}

export default function AddRoomMenu({
  anchorEl,
  open,
  onClose,
  onSelectRoom,
}: AddRoomMenuProps) {
  return (
    <Menu
      id="add-room-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-label': 'Add a Room Menu',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Paper elevation={0} sx={{ width: 225, minHeight: 225, maxHeight: 465 }}>
        {ROOM_LIST.map((room) => (
          <MenuItem key={room.id} onClick={() => onSelectRoom(room)}>
            {room.classification}
          </MenuItem>
        ))}
      </Paper>
    </Menu>
  );
}
