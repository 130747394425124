import { Item } from '@/types/Item';

export const convertItemsToSnakeCase = ({
  notGoing,
  roomId,
  segmentId,
  isoCode,
  definitionId,
  ...rest
}: Item) => ({
  ...rest,
  room_id: roomId,
  segment_id: segmentId,
  not_going: notGoing,
  iso_code: isoCode,
  definition_id: definitionId,
});
