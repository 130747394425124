import { useAtomValue } from 'jotai';
import {
  itemsTotalsAtom,
  orderItemsTotalsAtom,
  proGearItemsTotalsAtom,
  segmentItemsTotalsAtom,
  weightFactorAtom,
} from '../store';

export const useSurveyReport = () => {
  const itemsTotal = useAtomValue(itemsTotalsAtom);
  const segmentItemsTotal = useAtomValue(segmentItemsTotalsAtom);
  const orderItemsTotal = useAtomValue(orderItemsTotalsAtom);
  const proGearItemsTotal = useAtomValue(proGearItemsTotalsAtom);
  const weightFactor = useAtomValue(weightFactorAtom);

  return {
    itemsTotal,
    orderItemsTotal,
    proGearItemsTotal,
    segmentItemsTotal,
    weightFactor,
  };
};
