import { Typography, Menu, MenuItem } from '@mui/material';

interface SegmentActionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onRemoveSegment?: () => void;
  onEditSegment?: () => void;
}

export function SegmentActionMenu({
  anchorEl,
  open,
  onClose,
  onRemoveSegment,
  onEditSegment,
}: SegmentActionMenuProps) {
  return (
    <>
      <Menu
        id="room-row-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-label': 'basic-button',
        }}
      >
        {onEditSegment ? (
          <MenuItem onClick={onEditSegment}>
            <Typography>Edit Segment</Typography>
          </MenuItem>
        ) : null}
        {onRemoveSegment ? (
          <MenuItem onClick={onRemoveSegment}>
            <Typography color="error">Remove Segment</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
