import { IconButton, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { NumberField } from '@/components';

interface QuantityFieldProps {
  id?: string;
  name: string;
  value: number | null | undefined;
  onChange: (value: number) => void;
}

export function QuantityField({
  id,
  name,
  value = 0,
  onChange,
}: QuantityFieldProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <IconButton
        aria-label="decrement-button"
        onClick={() => {
          const newValue = Number(value) - 1 < 0 ? 0 : Number(value) - 1;
          onChange(newValue);
        }}
      >
        <RemoveIcon />
      </IconButton>

      <NumberField
        id={id}
        name={name}
        inputMode="numeric"
        size="small"
        value={value}
        onChange={(value) => onChange(Number(value))}
        sx={{
          flex: 1,
        }}
      />

      <IconButton
        aria-label="increment-button"
        onClick={() => onChange(Number(value) + 1)}
      >
        <AddIcon />
      </IconButton>
    </Stack>
  );
}
