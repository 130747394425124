import { createTheme, ThemeOptions } from '@mui/material/styles';
import { components } from './components';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1D4E89',
    },
    secondary: {
      main: '#0C4C6F',
    },
    background: {
      default: '#FAFAFB',
    },
    text: {
      secondary: 'rgba(0,0,0,0.6)',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#ED6C02',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
    },
  },
  typography: {
    fontFamily: 'Inter, Sans-Serif',
  },
  components,
};

export const theme = createTheme(themeOptions);
