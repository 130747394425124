import { SurveyServiceCompletionStatus } from '@/types/SurveyService';

export const transformResponse = ({
  id,
  attributes: { status, subtype, completion_status },
}: {
  id: string;
  attributes: {
    status: string;
    subtype: string;
    completion_status: SurveyServiceCompletionStatus;
  };
}) => ({
  service: {
    id,
    status,
    subtype,
    completionStatus: completion_status,
  },
});
