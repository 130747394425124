import Link from 'next/link';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { CRMButton } from '@/components/common/CRMButton';

export function NotFound() {
  return (
    <Stack
      width={1}
      height={1}
      gap={2}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4">No Survey or Service Found</Typography>
      <CRMButton variant="contained" size="large">
        Back to HSC CRM
      </CRMButton>
    </Stack>
  );
}
