import { atom } from 'jotai';
import { itemsAtom } from './itemsAtom';
import { selectedSegmentIdAtom } from './selectedSegmentIdAtom';
import { segmentsAtom } from './segmentsAtom';
import { appAtom } from '@/store/appAtom';

export const orderItemsAtom = atom((get) => {
  const items = get(itemsAtom) || [];
  const segments = get(segmentsAtom);
  const selectedSegmentId = get(selectedSegmentIdAtom);
  const selectedSegment = segments.find(({ id }) => id === selectedSegmentId);
  const selectedOrderId = selectedSegment?.orderId;
  const orderSegments = segments.filter(
    ({ orderId }) => orderId === selectedOrderId
  );
  const segmentIds = orderSegments.map((segment) => segment.id);
  return items.filter(({ segmentId }) => segmentIds.includes(segmentId));
});
