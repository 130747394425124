import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextButton } from '@/components';
import { Segment } from '@/types';
import Stack from '@mui/material/Stack';

interface ConfirmRemoveSegmentModalProps {
  open: boolean;
  segment: Segment;
  itemsTotal: number;
  onCancel: () => void;
  onConfirm: (segment: Segment) => void;
}

export function ConfirmRemoveSegmentModal({
  open,
  segment,
  itemsTotal,
  onCancel,
  onConfirm,
}: ConfirmRemoveSegmentModalProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Remove Segment</DialogTitle>

      <DialogContent>
        Are you sure you would like to remove this segment?
        {itemsTotal > 0 ? (
          <Stack direction="row" justifyContent="center" marginTop={2}>
            <strong>
              {itemsTotal} {itemsTotal === 1 ? 'item' : 'items'} in this segment
              will be removed.
            </strong>
          </Stack>
        ) : null}
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onCancel}>Cancel</TextButton>
        <TextButton color="error" onClick={() => onConfirm(segment)}>
          Remove Segment
        </TextButton>
      </DialogActions>
    </Dialog>
  );
}
