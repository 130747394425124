import { useState, MouseEvent } from 'react';
import Link from 'next/link';
import {
  CardContent,
  List,
  Divider,
  ListItem,
  IconButton,
  ListItemButton,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RoomActionMenu, EditRoomModal } from '@/components/common';
import { Item, Room } from '@/types';
import { getTotals } from '@/utils';
import ConfirmRemoveRoomModal from './ConfirmRemoveRoomModal';
import ConfirmRemoveRoomItemsModal from './ConfirmRemoveRoomItemsModal';

interface RoomListFullProps {
  rooms: Array<Room>;
  items: Array<Item>;
  onRemoveRoom: (room: Room) => void;
  onRemoveRoomItems: (room: Room) => void;
  onEditRoom: (room: Room) => void;
}

interface ActionState {
  selectedRoom: Room | null;
  actionMenuAnchorEl: HTMLElement | null;
  isActionMenuOpen: boolean;
  isEditModalOpen: boolean;
  isConfirmRemoveItemsModalOpen: boolean;
  isConfirmRemoveModalOpen: boolean;
}

export function RoomListFull({
  rooms,
  items,
  onRemoveRoom,
  onRemoveRoomItems,
  onEditRoom,
}: RoomListFullProps) {
  const [actionState, setActionState] = useState<ActionState>({
    actionMenuAnchorEl: null,
    isActionMenuOpen: false,
    isEditModalOpen: false,
    isConfirmRemoveItemsModalOpen: false,
    isConfirmRemoveModalOpen: false,
    selectedRoom: null,
  });

  const onActionMenuClose = () => {
    setActionState({
      ...actionState,
      actionMenuAnchorEl: null,
      isActionMenuOpen: false,
    });
  };

  const onActionMenuButtonClick =
    (room: Room) => (event: MouseEvent<HTMLButtonElement>) => {
      setActionState({
        ...actionState,
        actionMenuAnchorEl: event.currentTarget,
        selectedRoom: room,
        isActionMenuOpen: true,
      });
    };

  const openModal = (modal: string) => {
    setActionState({
      ...actionState,
      isActionMenuOpen: false,
      [modal]: true,
    });
  };

  const closeModal = (modal: string) => {
    setActionState({
      ...actionState,
      selectedRoom: null,
      [modal]: false,
    });
  };

  const onConfirmRemoveRoom = (room: Room) => {
    onRemoveRoom(room);
    closeModal('isConfirmRemoveModalOpen');
  };

  const onConfirmRemoveRoomItems = (room: Room) => {
    onRemoveRoomItems(room);
    closeModal('isConfirmRemoveItemsModalOpen');
  };

  const onEditRoomChange = (room: Room) => {
    onEditRoom(room);
    closeModal('isEditModalOpen');
  };

  return (
    <CardContent sx={{ height: 1, overflowY: 'auto' }}>
      <List component="div" aria-label="Room Items" disablePadding={true}>
        <Divider />
        {rooms.map((room) => {
          const roomItems = items.filter((item) => item.roomId === room.id);
          const { going, weight, cube } = getTotals(roomItems);

          return (
            <ListItem
              key={room.id}
              divider
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="Room Menu"
                  onClick={onActionMenuButtonClick(room)}
                >
                  <MoreVertIcon />
                </IconButton>
              }
            >
              <ListItemButton
                component={Link}
                href={`/rooms?id=${encodeURIComponent(room.id)}`}
                disableRipple
              >
                <div>
                  <Typography variant="subtitle2">{room.name}</Typography>
                  <Typography variant="subtitle2">
                    {going} Items, {cube} cu ft, {weight} lbs
                  </Typography>
                </div>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <RoomActionMenu
        anchorEl={actionState.actionMenuAnchorEl}
        open={actionState.isActionMenuOpen}
        onClose={onActionMenuClose}
        onRemoveRoom={() => openModal('isConfirmRemoveModalOpen')}
        onRemoveRoomItems={() => openModal('isConfirmRemoveItemsModalOpen')}
        onEditRoom={() => openModal('isEditModalOpen')}
      />
      {actionState.isEditModalOpen && actionState.selectedRoom ? (
        <EditRoomModal
          open={actionState.isEditModalOpen}
          room={actionState.selectedRoom}
          onClose={() => closeModal('isEditModalOpen')}
          onChange={onEditRoomChange}
        />
      ) : null}
      {actionState.isConfirmRemoveItemsModalOpen && actionState.selectedRoom ? (
        <ConfirmRemoveRoomItemsModal
          open={actionState.isConfirmRemoveItemsModalOpen}
          room={actionState.selectedRoom}
          onCancel={() => closeModal('isConfirmRemoveItemsModalOpen')}
          onConfirm={onConfirmRemoveRoomItems}
        />
      ) : null}
      {actionState.isConfirmRemoveModalOpen && actionState.selectedRoom ? (
        <ConfirmRemoveRoomModal
          open={actionState.isConfirmRemoveModalOpen}
          room={actionState.selectedRoom}
          onCancel={() => closeModal('isConfirmRemoveModalOpen')}
          onConfirm={onConfirmRemoveRoom}
        />
      ) : null}
    </CardContent>
  );
}
