import { useCallback } from 'react';
import { AppState } from '@/types/App';
import { useApp } from './useApp';

export const useAppState = (): [AppState, (newState: AppState) => void] => {
  const [app, setApp] = useApp();

  const setAppState = useCallback(
    (newState: AppState) => {
      setApp((prevApp) => ({
        ...prevApp,
        state: newState,
      }));
    },
    [setApp]
  );

  return [app.state, setAppState];
};
