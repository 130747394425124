import Button, { ButtonProps } from '@mui/material/Button';
import { CRM_HOST } from '@/constants';

export function CRMButton({
  children,
  size,
  variant,
  path = '',
}: { path?: string } & ButtonProps) {
  return (
    <Button
      href={`${CRM_HOST}/${path}`}
      rel="noreferrer"
      target="_blank"
      size={size ?? 'small'}
      variant={variant ?? 'text'}
    >
      {children}
    </Button>
  );
}

export const CRMButtonSignIn = (props: ButtonProps) => {
  return (
    <CRMButton path="index.php" {...props}>
      {props.children}
    </CRMButton>
  );
};
