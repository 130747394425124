export const OBJECT_METHOD = {
  CREATE: 'create',
  DESTROY: 'destroy',
  UPDATE: 'update',
} as const;

export const OBJECT_METHODS = [
  OBJECT_METHOD.CREATE,
  OBJECT_METHOD.DESTROY,
  OBJECT_METHOD.UPDATE,
];
