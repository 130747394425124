import { SERVICE_COMPLETION_STATUS } from '@/constants/surveyService';
import { useApp } from './useApp';

const startedServiceStatuses = [
  SERVICE_COMPLETION_STATUS.IN_PROGRESS,
  SERVICE_COMPLETION_STATUS.IN_REVIEW,
  SERVICE_COMPLETION_STATUS.PERFORMED,
] as const;
type StartedServiceStatus = (typeof startedServiceStatuses)[number];

export const useHasSurveyBeenStarted = () => {
  const [app] = useApp();

  const hasSurveyBeenStarted = startedServiceStatuses.includes(
    app.service?.completionStatus as StartedServiceStatus
  );

  return hasSurveyBeenStarted;
};
