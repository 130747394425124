import { Stack, Typography } from '@mui/material';
import Link from 'next/link';

export function LoadingError() {
  return (
    <Stack width={1} height={1} justifyContent="center" alignItems="center">
      <Typography variant="h4">Error loading survey or service</Typography>
    </Stack>
  );
}
