import { useState, useEffect, createContext, ReactNode } from 'react';

interface OnlineStatusProviderProps {
  children: ReactNode;
}

export const OnlineStatusContext = createContext(true);

function getOnlineStatus() {
  return typeof navigator !== 'undefined' &&
    typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
}

export const OnlineStatusProvider = ({
  children,
}: OnlineStatusProviderProps) => {
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
  const setOffline = () => setOnlineStatus(false);
  const setOnline = () => setOnlineStatus(true);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};
